import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { NavStrings } from "../constants";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ClipLoader from "react-spinners/ClipLoader";

interface ImageTextLayoutProps {
  imageSrc: string;
  subtitle?: string;
  title: string;
  description: string;
  description2?: string;
  buttonTxt?: string;
  buttonTxt2?: string;
  buttonTxt3?: string;
  reverse?: boolean;
  showInputs?: boolean;
}

const ImageTextLayout: React.FC<ImageTextLayoutProps> = ({
  imageSrc,
  subtitle,
  title,
  description,
  description2,
  buttonTxt,
  buttonTxt2,
  buttonTxt3,
  reverse,
  showInputs,
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const catalogSchema = yup.object().shape({
    name: yup
      .string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Name is required"),
    email: yup.string().email("Invalid email").required("Email is required"),
    mobile: yup
      .string()
      .matches(/^[0-9]{10}$/, "Invalid mobile number")
      .required("Mobile is required"),
    designation: yup.string().required("Designation is required"),
    organization: yup.string().required("Organization is required"),
  });

  const handleSubmit = async (values: any, { resetForm }: any) => {
    setLoading(true);
    const formData = new FormData();
    formData.append(
      "access_key",
      process.env.REACT_APP_WEB3FORMS_ACCESS_KEY as string
    );
    for (const key in values) {
      formData.append(key, values[key]);
    }
    const object = Object.fromEntries(formData);
    const json = JSON.stringify(object);
    const res = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: json,
    }).then((res) => res.json());
    setLoading(false);
    if (res.success) {
      toast.success(
        "Thank You. Our Lens Catalog will be emailed to your email address."
      );
      resetForm();
      console.log("Form values:", values);
    } else {
      console.error("Submission failed:", res);
      console.log("Form values:", values);
      toast.error("There was an error submitting the form. Please try again.");
    }
  };

  const btnClickEventMethod = (
    e: React.FormEvent<HTMLFormElement>,
    buttonTxt: string,
    title: string,
    subtitle?: string
  ) => {
    if (buttonTxt.toLocaleLowerCase() === "Show Me Now".toLocaleLowerCase()) {
      navigate(NavStrings.STORELOCATOR);
    } else if (
      subtitle?.toLocaleLowerCase() ===
      "Clariviz Z Series DS".toLocaleLowerCase()
    ) {
      navigate(NavStrings.ZSERIESDS);
    } else if (
      subtitle?.toLocaleLowerCase() ===
      "Clariviz Z Series 2.0".toLocaleLowerCase()
    ) {
      navigate(NavStrings.ZSERIES20);
    } else if (
      subtitle?.toLocaleLowerCase() ===
      "Clariviz Z Series 1.0".toLocaleLowerCase()
    ) {
      navigate(NavStrings.ZSERIES10);
    } else if (
      subtitle?.toLocaleLowerCase() ===
      "Clariviz Digipro 2.0".toLocaleLowerCase()
    ) {
      navigate(NavStrings.DIGIPRO20);
    } else if (
      title.toLocaleLowerCase() === "CLARIVIZ TECHNOLOGIES".toLocaleLowerCase()
    ) {
      navigate(NavStrings.LENSTECHNOLOGIES);
    } else if (
      title.toLocaleLowerCase() === "CLARIVIZ COATINGS".toLocaleLowerCase()
    ) {
      navigate(NavStrings.ULTIPROUVCOATING);
    } else if (
      title.toLocaleLowerCase() ===
      "CLARIVIZ PERSONALIZATION".toLocaleLowerCase()
    ) {
      navigate(NavStrings.LENSPERSONALIZATION);
    } else if (
      buttonTxt.toLocaleLowerCase() === "Send your CV".toLocaleLowerCase()
    ) {
      navigate(NavStrings.CAREERS);
    } else if (
      buttonTxt.toLocaleLowerCase() ===
      "Become a Clariviz Partner".toLocaleLowerCase()
    ) {
      navigate(NavStrings.CLARIVIZLENSES);
    }
  };
  return (
    <div className="max-w-screen-2xl mx-auto px-custom-padding py-[1.45rem]">
      <div
        className={`flex flex-col md:flex-row ${
          reverse ? "md:flex-row-reverse" : ""
        } items-start bg-[#CCCCCC] rounded-sm p-[1.45rem] md:p-[2.45rem] space-y-4 md:space-y-0 md:space-x-8 ${
          reverse ? "md:space-x-reverse" : ""
        }`}
      >
        <div className="w-full md:w-1/3">
          <LazyLoadImage
            loading="lazy"
            alt={title}
            height={"100%"}
            wrapperProps={{
              style: { transitionDelay: "1s" },
            }}
            src={imageSrc}
            width={"100%"}
          />
        </div>
        <div className="w-full md:w-2/3 flex flex-col gap-6 ">
          <div className="flex flex-col gap-4 xs:gap-6 md:gap-8">
            <div className="flex flex-col md:gap-2">
              <h1
                className={`${
                  subtitle
                    ? "text-xl md:text-45pt font-light text-black md:leading-[2.55rem]"
                    : "hidden"
                }`}
              >
                {subtitle}
              </h1>
              <div className="w-full md:w-[80%]">
                <h2 className="text-2xl leading-tight md:text-45pt font-bold text-black md:leading-[2.55rem]">
                  {title}
                </h2>
              </div>
            </div>
            <div>
              <div className="flex flex-col gap-2">
                <p
                  className={`${
                    buttonTxt2 ? "text-tinybase" : "text-base md:text-15pt"
                  } leading-relaxed md:leading-8 w-[90%]`}
                >
                  {description}
                </p>
                <p
                  className={`${
                    description2 ? "text-tinybase" : "text-15pt"
                  } leading-relaxed md:leading-8 w-[90%]`}
                >
                  {description2}
                </p>
              </div>
            </div>
          </div>

          <div>
            {showInputs && (
              <>
                <Formik
                  initialValues={{
                    name: "",
                    email: "",
                    mobile: "",
                    designation: "",
                    organization: "",
                  }}
                  validationSchema={catalogSchema}
                  onSubmit={handleSubmit}
                >
                  {({ isSubmitting }) => (
                    <Form>
                      <div className="mt-2 mb-8 grid grid-cols-1 xs:grid-cols-2 lg:grid-cols-3 gap-4">
                        <div>
                          <label htmlFor="name" className="sr-only">
                            Name
                          </label>
                          <Field
                            type="text"
                            name="name"
                            id="name"
                            className="w-full px-4 py-2 text-slate-800
              border border-slate-400 rounded-md focus:outline-none"
                            placeholder="Name"
                            autoComplete="name"
                          />
                          <ErrorMessage
                            name="name"
                            component="div"
                            className="text-red-600 text-sm"
                          />
                        </div>

                        <div>
                          <label htmlFor="email" className="sr-only">
                            Email
                          </label>
                          <Field
                            type="email"
                            name="email"
                            id="email"
                            placeholder="Email"
                            className="w-full px-4 py-2 text-slate-800 border border-slate-400 
                          rounded-md focus:outline-none"
                            autoComplete="email"
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="text-red-600 text-sm"
                          />
                        </div>

                        <div>
                          <label htmlFor="mobile" className="sr-only">
                            Mobile
                          </label>
                          <Field
                            type="text"
                            name="mobile"
                            id="mobile"
                            placeholder="Mobile"
                            className="w-full px-4 py-2 text-slate-800 border border-slate-400 
                        rounded-md focus:outline-none"
                            autoComplete="tel"
                          />
                          <ErrorMessage
                            name="mobile"
                            component="div"
                            className="text-red-600 text-sm"
                          />
                        </div>

                        <div>
                          <label htmlFor="designation" className="sr-only">
                            Designation
                          </label>
                          <Field
                            type="text"
                            name="designation"
                            id="designation"
                            placeholder="Designation"
                            className="w-full px-4 py-2 text-slate-800 border border-slate-400 
                        rounded-md focus:outline-none"
                            autoComplete="organization-title"
                          />
                          <ErrorMessage
                            name="designation"
                            component="div"
                            className="text-red-600 text-sm"
                          />
                        </div>

                        <div>
                          <label htmlFor="organization" className="sr-only">
                            Organization
                          </label>
                          <Field
                            type="text"
                            name="organization"
                            id="organization"
                            placeholder="Organization Name"
                            className="w-full px-4 py-2 text-slate-800 border border-slate-400 
                          rounded-md focus:outline-none"
                            autoComplete="organization"
                          />
                          <ErrorMessage
                            name="organization"
                            component="div"
                            className="text-red-600 text-sm"
                          />
                        </div>
                      </div>

                      <div className="mt-2 grid grid-cols-1 xs:grid-cols-2 lg:grid-cols-3 gap-4">
                        <button
                          type="submit"
                          disabled={isSubmitting}
                          className="btn btn-secondary"
                        >
                          {/* Submit to Download */}
                          {loading ? (
                            <ClipLoader size={20} color={"#0A0A52"} />
                          ) : (
                            buttonTxt || "Submit to Download"
                          )}
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
                <ToastContainer />
              </>
            )}
            {buttonTxt && !showInputs && (
              <div className="flex">
                <div className="flex flex-col gap-4 xs:flex-row">
                  <button
                    type="submit"
                    className="btn btn-secondary"
                    onClick={(e: any) => {
                      btnClickEventMethod(e, buttonTxt, title, subtitle);
                      window.scrollTo({ top: 0, behavior: "smooth" });
                    }}
                  >
                    {buttonTxt}
                  </button>
                  {buttonTxt2 && buttonTxt3 && (
                    <>
                      <button
                        className="btn btn-secondary"
                        onClick={() => {
                          navigate(NavStrings.ZSERIES20);
                          window.scrollTo({ top: 0, behavior: "smooth" });
                        }}
                      >
                        {buttonTxt2}
                      </button>
                      <button
                        className="btn btn-secondary"
                        onClick={() => {
                          navigate(NavStrings.ZSERIES10);
                          window.scrollTo({ top: 0, behavior: "smooth" });
                        }}
                      >
                        {buttonTxt3}
                      </button>
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageTextLayout;
