import React from "react";
import { SEO } from "../components";
import { NavStrings, UrlConstants } from "../constants";

const PrivacyPolicy = () => {
  const UrlLink = UrlConstants.base_url.concat(NavStrings.PRIVACYPOLICY);
  return (
    <>
      <SEO
        title="Privacy Policy - Clariviz"
        description="Read our Privacy Policy to understand how we protect and manage your personal information, including details on data collection, usage, and third-party sharing practices."
        keywords="privacy policy, data protection, personal information, privacy practices, user data"
        name="Privacy Policy"
        type="website"
        canonicallink={UrlLink}
      />
      <section className="pt-8 flex flex-col px-custom-padding font-montserrat">
        <h1 className="text-3xl text-primary font-bold">Privacy Policy</h1>
        <h4 className="mt-2 text-primary font-medium text-md">
          Clariviz welcomes you. This section pertains to the Privacy Policy of
          the website. Please note that our privacy policy is subject to change
          without intimation. You are requested to review it regularly.
        </h4>
        <p className="text-15pt text-black leading-8 w-[98%] mt-8">
          The protection and security of your personal information are among
          Clariviz’s top priorities. This Privacy Policy discloses Clariviz’s
          practice concerning the information collected from the users of the
          website. Eyegear Optics India Private Limited is authorised to use the
          Clariviz website for commercial purposes. This Privacy Policy extends
          to both users who visit the website but do not transact business on
          the website, as well as users who are registered on the website. By
          accessing or using the website, you agree to accept the terms of this
          Privacy Policy as well as the website's Terms of Use. You also
          expressly consent to our use and disclosure of your personal
          information in any manner described in this Privacy Policy. "Personal
          Information," in this context, refers to any information that
          identifies or can be used to identify, contact or locate the person,
          to whom such information pertains including, but not limited to, name,
          address, phone number, fax number, email address, financial profiles,
          identification number, credit card information, etc.
        </p>
        <div className="pt-8 pb-4 flex flex-col">
          <h3 className="font-medium text-primary text-lg">
            PLEASE READ THE FOLLOWING TERMS OF OUR PRIVACY POLICY
          </h3>
          <p className="text-15pt text-black leading-8 w-[98%] pt-4">
            By accepting this privacy policy, you authorise Clariviz to collect,
            store and use any information that you provide on our website. The
            information collected by us includes:
          </p>
          <ul className="p-4 ml-4">
            <li className="list-disc text-15pt text-black leading-8 w-[98%]">
              All information entered by you on our website, such as your name,
              address, contact number, email ID, chat history and such other
              information sent by you via email to our email ID.
            </li>
            <li className="list-disc text-15pt text-black leading-8 w-[98%]">
              Information collected using cookies that are installed on your
              hard drive.
            </li>
            <li className="list-disc text-15pt text-black leading-8 w-[98%]">
              Information, such as the IP address of your computer, the server
              from which you are accessing our website, and details of the web
              browser and operating system used to access our website and or
              date, time and place of accessing of our website, etc.
            </li>
          </ul>
        </div>
        <div className="py-2 flex flex-col">
          <h3 className="font-medium text-primary text-lg">
            USE OF INFORMATION COLLECTED
          </h3>
          <p className="text-15pt text-black leading-8 w-[98%] pt-4 ml-4">
            Clariviz owns all the information collected via the website or
            applications installed on the website. The information collected by
            Clariviz shall be used to contact you about the website and related
            news and services available on the website; to monitor and improve
            the website; to calculate the number of visitors to the website and
            to know the geographical locations of the visitors; to update you on
            all the special offers available on the website and to provide you
            with a better shopping experience. It includes sending emails to you
            about the various offers on the website.
          </p>
          <p className="text-15pt text-black leading-8 w-[98%] pt-4 ml-4">
            You may, at any time, choose to unsubscribe from such emails. Some
            of your information may be shared with and used by third parties who
            shall need to have access to information to enable them and Clariviz
            to perform their duties and fulfill your order requirements. These
            include courier companies, credit card processing companies,
            vendors, etc. Clariviz does not allow any unauthorised persons or
            organisations to use any information that it may collect from you
            through the website. However, Clariviz is not responsible for any
            information collected or shared or used by any other third-party
            website due to your browser settings.
          </p>
          <p className="text-15pt text-black leading-8 w-[98%] pt-4 ml-4">
            Clariviz reserves the right to share any of your personal
            information to comply with court orders or other legal processes.
            Your personal information may be disclosed under such court order or
            legal process, which shall be without notice to you.
          </p>
          <p className="text-15pt text-black leading-8 w-[98%] pt-4 ml-4">
            Clariviz may share collective information, such as demographics and
            website or mobile application usage statistics with our sponsors,
            advertisers or other third parties. Such third parties do not
            include Clariviz’s marketing partners and network providers. When
            this type of information is shared, such parties do not have access
            to your personal information. When you contact Clariviz through any
            means, such as chat/email, Clariviz reserves the right to include
            your email ID for marketing communications. You can unsubscribe from
            such communications any time you wish to do so.
          </p>
          <p className="text-15pt text-black leading-8 w-[98%] pt-4 ml-4">
            The website may contain links, which may lead you to other websites.
            Please note that once you leave our website, you will be subjected
            to the Privacy Policy of the other website, and this our Privacy
            Policy will no longer apply.
          </p>
        </div>
        <p className="text-15pt leading-8 w-[98%] py-4 font-medium text-primary">
          BY USING THE WEBSITE, YOU SIGNIFY YOUR AGREEMENT TO THE TERMS OF THIS
          PRIVACY POLICY. CLARIVIZ RESERVES THE RIGHT, IN OUR SOLE DISCRETION,
          TO CHANGE, MODIFY, ADD OR DELETE PORTIONS OF THE TERMS OF THIS PRIVACY
          POLICY AT ANY TIME.
        </p>
        <p className="text-15pt text-black leading-8 w-[98%] py-4">
          If you have any questions about this Privacy Policy, please feel free
          to contact us through our website.
        </p>
      </section>
    </>
  );
};

export default PrivacyPolicy;
