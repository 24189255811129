import React from "react";
import HeaderImg from "../../assets/images/about-us/manufacturing-unit/HeaderImg.jpg";
import Careers from "../../assets/images/Careers.jpg";
import StoreImg from "../../assets/images/store-locator.jpg";
import { ImageTextLayout, SEO } from "../../components";
import { NavStrings, UrlConstants } from "../../constants";

const LensManufacturingUnit = () => {
  const UrlLink = UrlConstants.base_url.concat(
    NavStrings.LENSMANUFACTURINGUNIT
  );
  return (
    <section className="w-[100%] max-w-screen-2xl mx-auto">
      <SEO
        title="Lens Manufacturing Unit - Clariviz"
        description="Explore Clariviz's state-of-the-art lens manufacturing unit. Watch our video to understand why Clariviz offers some of the best lenses available."
        keywords="Lens Manufacturing Unit, Clariviz, eyewear, optical lenses, manufacturing, quality lenses"
        name="Lens Manufacturing Unit"
        type="website"
        canonicallink={UrlLink}
      />
      <div className="relative w-full h-[30vh] xs:h-[35vh] small:h-[40vh] md:h-[50vh] lg:h-[55vh] xl:h-[60vh]">
        <img
          src={HeaderImg}
          alt="Lens Color header pic"
          className="w-[100%] h-[100%] object-cover"
        />
      </div>
      <div className="pt-8 flex flex-col px-custom-padding">
        <div>
          <h1 className="text-2xl md:text-3xl text-primary font-bold">
            Lens Manufacturing Unit
          </h1>
          <p className="mt-8 tracking-wide text-primary text-lg">
            Watch the video to learn more about why Clariviz is possibly the
            best lens option for you
          </p>
          <div
            className="my-12 mx-18 w-full h-[40vh] xs:h-[50vh] small:h-[55vh] md:h-[65vh] 
        bg-[#CCCCCC] rounded-md flex items-center justify-center text-primary font-medium text-md"
          >
            Video Container
          </div>
        </div>
      </div>
      <ImageTextLayout
        imageSrc={Careers}
        title="Careers at Clariviz"
        description="We're a dynamic and innovative eyewear company which values its 
        employees and is committed to creating a positive, inclusive work environment. 
        If you're passionate about eyewear and looking for an exciting and 
        upwardly-mobile career opportunity, then you're welcome to apply!"
        buttonTxt="Send your CV"
        reverse
      />
      <ImageTextLayout
        imageSrc={StoreImg}
        subtitle="Clariviz Store Locator"
        title="Find a Store near You"
        description="With over 500 hospital retail locations and 8000+ mom-and-pop stores nationwide, 
        you're never far from the exceptional products and services you love. Whether you're planning 
        a visit or need to check availability, we're here to make your shopping experience seamless and 
        convenient. Discover your nearest store today!"
        buttonTxt="Show Me Now"
      />
    </section>
  );
};

export default LensManufacturingUnit;
