import {
  IoChevronDown,
  IoChevronForwardOutline,
  IoClose,
  IoMenu,
} from "react-icons/io5";
import { useEffect, useLayoutEffect, useRef, useState } from "react";

import { Link } from "react-router-dom";
import { NavStrings } from "../constants";
import logo from "../assets/images/logo.png";
import { useScrollPosition } from "../hooks";
import { Accordion, AccordionSummary } from "@mui/material";

const Navbar = () => {
  const dropdownRef = useRef<any>(null);
  const dropdownAboutRef = useRef<any>(null);
  const [menuToggle, setMenuToggle] = useState(false);
  const [dropdownToggle, setDropdownToggle] = useState(false);
  const [aboutusToggle, setAboutusToggle] = useState(false);
  const [subLensPortfolioToggle, setSubLensPortfolioToggle] = useState(false);
  const [subLensCoatingToggle, setSubLensCoatingToggle] = useState(false);
  const [expanded, setExpanded] = useState<string | false>(false);

  const handlePortfolioChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  const scrollPosition = useScrollPosition();

  const closeMenu = () => {
    setMenuToggle(false);
  };
  useLayoutEffect(() => {
    const isDesktopOrLaptop = window.matchMedia("(min-width: 810px)").matches;

    if (isDesktopOrLaptop && scrollPosition > 12) {
      setDropdownToggle(false);
      setAboutusToggle(false);
    }
  }, [scrollPosition]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setDropdownToggle(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    const handleClickOutsideAbout = (event: MouseEvent) => {
      if (
        dropdownAboutRef.current &&
        !dropdownAboutRef.current.contains(event.target as Node)
      ) {
        setAboutusToggle(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutsideAbout);

    return () => {
      document.removeEventListener("mousedown", handleClickOutsideAbout);
    };
  }, []);
  return (
    <nav
      className={`bg-primary w-[100%] max-w-screen-2xl 
    mx-auto px-custom-padding h-[8vh] flex items-center relative
    ${scrollPosition > 60 ? "sticky top-0 z-10" : ""}`}
    >
      <div className="flex items-center justify-between w-full">
        <div className="relative w-[160px]">
          <Link
            to={NavStrings.HOME}
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          >
            <img
              src={logo}
              alt="clariviz logo"
              width={"100%"}
              height={"100%"}
            />
          </Link>
        </div>
        <ul className="hidden md:text-white md:flex align-center text-base gap-6">
          <li className="hover:text-slate-400">
            <Link
              to={NavStrings.HOME}
              className="text-base"
              onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            >
              Home
            </Link>
          </li>
          <li className="relative group transition-all" ref={dropdownRef}>
            <button
              onClick={() => {
                setDropdownToggle(!dropdownToggle);
                setSubLensPortfolioToggle(false);
                setSubLensCoatingToggle(false);
                setAboutusToggle(false);
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
              className="hover:text-slate-400 w-full px-2 flex items-center justify-between"
            >
              <Link to={NavStrings.CLARIVIZLENSES}>Clariviz Lenses</Link>
              <IoChevronDown size={22} className="pl-1" />
            </button>
            {dropdownToggle && (
              <ul className="absolute left-0 mt-2 w-[140%] bg-primary shadow-lg rounded-sm py-1 text-white z-20">
                <li className="text-tinybase relative">
                  <button
                    onClick={() => {
                      setSubLensPortfolioToggle(!subLensPortfolioToggle);
                      setSubLensCoatingToggle(false);
                      window.scrollTo({ top: 0, behavior: "smooth" });
                    }}
                    className="w-full text-left p-3 flex items-center justify-between hover:bg-slate-400/30 "
                  >
                    Lens Portfolio
                    <IoChevronForwardOutline
                      color="white"
                      size={22}
                      className="pl-1"
                    />
                  </button>
                  {subLensPortfolioToggle && (
                    <ul className="absolute top-0 left-full mt-[0.015rem] ml-0 w-[110%] bg-primary shadow-lg rounded-sm py-1 text-white z-30">
                      <Accordion
                        expanded={expanded === "panel1"}
                        onChange={handlePortfolioChange("panel1")}
                        sx={{
                          backgroundColor: "transparent",
                          boxShadow: "none",
                        }}
                      >
                        <AccordionSummary
                          expandIcon={<IoChevronDown color="white" />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          <div className="text-white">Progressive</div>
                        </AccordionSummary>
                        <div className="bg-lightPrimary/70">
                          <Link
                            to={NavStrings.ZSERIESDS}
                            onClick={() => {
                              setDropdownToggle(false);
                              setSubLensPortfolioToggle(false);
                            }}
                            className="text-tinybase p-3 w-full text-white flex hover:bg-slate-400/30"
                          >
                            Clariviz Z Series DS
                          </Link>
                          <Link
                            to={NavStrings.ZSERIES20}
                            onClick={() => {
                              setDropdownToggle(false);
                              setSubLensPortfolioToggle(false);
                            }}
                            className="text-tinybase px-4 py-2 w-full text-white flex hover:bg-slate-400/30"
                          >
                            Clariviz Z Series 2.0
                          </Link>
                          <Link
                            to={NavStrings.ZSERIES10}
                            onClick={() => {
                              setDropdownToggle(false);
                              setSubLensPortfolioToggle(false);
                            }}
                            className="text-tinybase p-3 w-full text-white flex hover:bg-slate-400/30"
                          >
                            Clariviz Z Series 1.0
                          </Link>
                          <Link
                            to={NavStrings.DIGIPRO20}
                            onClick={() => {
                              setDropdownToggle(false);
                              setSubLensPortfolioToggle(false);
                            }}
                            className="text-tinybase p-3 w-full text-white flex hover:bg-slate-400/30"
                          >
                            Clariviz Digi Pro 2.0
                          </Link>
                          <Link
                            to={NavStrings.DIGIPRO10}
                            onClick={() => {
                              setDropdownToggle(false);
                              setSubLensPortfolioToggle(false);
                            }}
                            className="text-tinybase p-3 w-full text-white flex hover:bg-slate-400/30"
                          >
                            Clariviz Digi Pro 1.0
                          </Link>
                          <Link
                            to={NavStrings.PROVIEW20}
                            onClick={() => {
                              setDropdownToggle(false);
                              setSubLensPortfolioToggle(false);
                            }}
                            className="text-tinybase p-3 w-full text-white flex hover:bg-slate-400/30"
                          >
                            Clariviz ProView 2.0
                          </Link>
                          <Link
                            to={NavStrings.PROVIEW10}
                            onClick={() => {
                              setDropdownToggle(false);
                              setSubLensPortfolioToggle(false);
                            }}
                            className="text-tinybase p-3 w-full text-white flex hover:bg-slate-400/30"
                          >
                            Clariviz ProView 1.0
                          </Link>
                          <Link
                            to={NavStrings.BENDIGITALPAL}
                            onClick={() => {
                              setDropdownToggle(false);
                              setSubLensPortfolioToggle(false);
                            }}
                            className="text-tinybase p-3 w-full text-white flex hover:bg-slate-400/30"
                          >
                            Ben Digital PALs
                          </Link>
                        </div>
                      </Accordion>
                      <Accordion
                        expanded={expanded === "panel2"}
                        onChange={handlePortfolioChange("panel2")}
                        sx={{
                          backgroundColor: "transparent",
                          boxShadow: "none",
                        }}
                      >
                        <AccordionSummary
                          expandIcon={<IoChevronDown color="white" />}
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <div className="text-white">Bifocal</div>
                        </AccordionSummary>
                        <div className="bg-lightPrimary/70">
                          <Link
                            to={NavStrings.RXBIFOCAL}
                            onClick={() => {
                              setDropdownToggle(false);
                              setSubLensPortfolioToggle(false);
                            }}
                            className="text-tinybase p-3 w-full text-white flex hover:bg-slate-400/30"
                          >
                            Clariviz RX Bifocal
                          </Link>
                        </div>
                      </Accordion>
                      <Accordion
                        expanded={expanded === "panel3"}
                        onChange={handlePortfolioChange("panel3")}
                        sx={{
                          backgroundColor: "transparent",
                          boxShadow: "none",
                        }}
                      >
                        <AccordionSummary
                          expandIcon={<IoChevronDown color="white" />}
                          aria-controls="panel3-content"
                          id="panel3-header"
                        >
                          <div className="text-white">Single Vision</div>
                        </AccordionSummary>
                        <div className="bg-lightPrimary/70">
                          <Link
                            to={NavStrings.CLARIVIZSINGLEVISION}
                            onClick={() => {
                              setDropdownToggle(false);
                              setSubLensPortfolioToggle(false);
                            }}
                            className="text-tinybase p-3 w-full text-white flex hover:bg-slate-400/30"
                          >
                            Clariviz SV
                          </Link>
                        </div>
                      </Accordion>
                      <Accordion
                        expanded={expanded === "panel4"}
                        onChange={handlePortfolioChange("panel4")}
                        sx={{
                          backgroundColor: "transparent",
                          boxShadow: "none",
                        }}
                      >
                        <AccordionSummary
                          expandIcon={<IoChevronDown color="white" />}
                          aria-controls="panel4-content"
                          id="panel4-header"
                        >
                          <div className="text-white">Special Lens</div>
                        </AccordionSummary>
                        <div className="bg-lightPrimary/70">
                          <Link
                            to={NavStrings.CLEARDRIVEPAL}
                            onClick={() => {
                              setDropdownToggle(false);
                              setSubLensPortfolioToggle(false);
                            }}
                            className="text-tinybase p-3 w-full text-white flex hover:bg-slate-400/30"
                          >
                            Clariviz ClearDrive PAL
                          </Link>
                          <Link
                            to={NavStrings.CLEARDRIVESV}
                            onClick={() => {
                              setDropdownToggle(false);
                              setSubLensPortfolioToggle(false);
                            }}
                            className="text-tinybase p-3 w-full text-white flex hover:bg-slate-400/30"
                          >
                            Clariviz ClearDrive SV
                          </Link>
                          <Link
                            to={NavStrings.CLARIVIZOFFICE}
                            onClick={() => {
                              setDropdownToggle(false);
                              setSubLensPortfolioToggle(false);
                            }}
                            className="text-tinybase p-3 w-full text-white flex hover:bg-slate-400/30"
                          >
                            Clariviz Office
                          </Link>
                          <Link
                            to={NavStrings.PIXELDIGI}
                            onClick={() => {
                              setDropdownToggle(false);
                              setSubLensPortfolioToggle(false);
                            }}
                            className="text-tinybase p-3 w-full text-white flex hover:bg-slate-400/30"
                          >
                            Pixel Digi
                          </Link>
                          <Link
                            to={NavStrings.QUICHROMES}
                            onClick={() => {
                              setDropdownToggle(false);
                              setSubLensPortfolioToggle(false);
                            }}
                            className="text-tinybase p-3 w-full text-white flex hover:bg-slate-400/30"
                          >
                            Clariviz Quichromes
                          </Link>
                        </div>
                      </Accordion>
                    </ul>
                  )}
                </li>
                <Link
                  to={NavStrings.LENSTECHNOLOGIES}
                  onClick={() => {
                    setDropdownToggle(false);
                    setSubLensPortfolioToggle(false);
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                >
                  <li className="text-tinybase p-3 w-full hover:bg-slate-400/30">
                    Lens Technologies
                  </li>
                </Link>
                <li className="text-tinybase relative">
                  <button
                    onClick={() => {
                      setSubLensCoatingToggle(!subLensCoatingToggle);
                      setSubLensPortfolioToggle(false);
                      window.scrollTo({ top: 0, behavior: "smooth" });
                    }}
                    className="w-full text-left px-4 py-2 flex items-center 
                    justify-between hover:bg-slate-400/30 "
                  >
                    Lens Coatings & Protection
                    <IoChevronForwardOutline
                      color="white"
                      size={22}
                      className="pl-1"
                    />
                  </button>
                  {subLensCoatingToggle && (
                    <ul className="absolute top-0 left-full mt-[0.015rem] ml-0 w-[110%] bg-primary shadow-lg rounded-sm py-1 text-white z-30">
                      <Link
                        to={NavStrings.ULTIPROUVCOATING}
                        onClick={() => {
                          setDropdownToggle(false);
                          setSubLensCoatingToggle(false);
                        }}
                        className="text-tinybase p-3 w-full flex hover:bg-slate-400/30"
                      >
                        ULTIPRO UV Coating
                      </Link>
                      <Link
                        to={NavStrings.ULTIPROBLUECOATING}
                        onClick={() => {
                          setDropdownToggle(false);
                          setSubLensCoatingToggle(false);
                        }}
                        className="text-tinybase p-3 w-full flex hover:bg-slate-400/30"
                      >
                        ULTIPRO Bluecut Coating
                      </Link>
                      <Link
                        to={NavStrings.HERCUECOATING}
                        onClick={() => {
                          setDropdownToggle(false);
                          setSubLensCoatingToggle(false);
                        }}
                        className="text-tinybase p-3 w-full flex hover:bg-slate-400/30"
                      >
                        Hercue Coating
                      </Link>
                    </ul>
                  )}
                </li>
                <Link
                  to={NavStrings.LENSCOLOUR}
                  onClick={() => {
                    setDropdownToggle(false);
                    setSubLensPortfolioToggle(false);
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                >
                  <li className="text-tinybase p-3 hover:bg-slate-400/30">
                    Lens Tints
                  </li>
                </Link>
                <Link
                  to={NavStrings.LENSPERSONALIZATION}
                  onClick={() => {
                    setDropdownToggle(false);
                    setSubLensPortfolioToggle(false);
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                >
                  <li className="text-tinybase p-3 hover:bg-slate-400/30">
                    Lens Personalization
                  </li>
                </Link>
              </ul>
            )}
          </li>
          <li
            className="relative group transition-all hover:text-slate-400"
            ref={dropdownAboutRef}
          >
            <button
              onClick={() => {
                setAboutusToggle(!aboutusToggle);
                setDropdownToggle(false);
              }}
              className="hover:text-slate-400 w-full flex items-center justify-between"
            >
              About Us
              <IoChevronDown size={22} className="pl-1" />
            </button>
            {aboutusToggle && (
              <ul className="absolute left-0  mt-2 w-[240%] bg-primary shadow-lg rounded-sm py-1 text-white z-20">
                <Link
                  to={NavStrings.CLARIVIZBYBENFRANKLIN}
                  onClick={() => {
                    setDropdownToggle(false);
                    setAboutusToggle(false);
                    setSubLensPortfolioToggle(false);
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                  className="text-tinybase px-4 py-2 w-full flex hover:bg-slate-400/30"
                >
                  Clariviz by Ben Franklin
                </Link>
                {/* <Link
                  to={NavStrings.LENSMANUFACTURINGUNIT}
                  onClick={() => {
                    setDropdownToggle(false);
                    setAboutusToggle(false);
                    setSubLensPortfolioToggle(false);
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                  className="text-tinybase px-4 py-2 w-full flex hover:bg-slate-400/30"
                >
                  Lens Manufacturing Unit
                </Link> */}
              </ul>
            )}
          </li>
          <li className="hover:text-slate-400">
            <Link
              to={NavStrings.CONTACTUS}
              onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            >
              Contact Us
            </Link>
          </li>
          <li className="hover:text-slate-400">
            <Link
              to={NavStrings.STORELOCATOR}
              onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            >
              Store Locator
            </Link>
          </li>
        </ul>
        <div className="md:hidden">
          <button
            onClick={() => setMenuToggle(!menuToggle)}
            className="transition ease-in-out delay-150"
          >
            {menuToggle ? (
              <IoClose color="white" size={26} />
            ) : (
              <IoMenu color="white" size={26} />
            )}
          </button>
        </div>

        {/* Mobile Menu */}
        {menuToggle && (
          <ul
            className="z-10 w-full absolute top-[8vh] left-0 min-h-[92vh] max-h-[calc(100vh-8vh)] overflow-auto bg-darkPrimary 
          p-custom-padding text-white space-y-6 md:hidden"
          >
            <li className="flex">
              <Link
                to={NavStrings.HOME}
                className="text-left px-2 py-2  rounded-sm hover:bg-slate-400/30"
                onClick={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                  closeMenu();
                }}
              >
                Home
              </Link>
            </li>
            <li className="text-tinybase relative" ref={dropdownRef}>
              <button
                onClick={() => {
                  setDropdownToggle(!dropdownToggle);
                  setSubLensPortfolioToggle(false);
                  setSubLensCoatingToggle(false);
                  setAboutusToggle(false);
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                className="w-max-content text-left rounded-sm flex items-center px-2 py-2 hover:bg-slate-400/30 "
              >
                <Link to={NavStrings.CLARIVIZLENSES}>Clariviz Lenses</Link>
                <IoChevronDown size={22} className="pl-1" />
              </button>
              {dropdownToggle && (
                <ul className="w-[55%] xs:ml-2 mt-1 bg-primary shadow-lg rounded-sm py-1 text-white">
                  <li className="relative">
                    <button
                      onClick={() => {
                        setSubLensPortfolioToggle(!subLensPortfolioToggle);
                        setSubLensCoatingToggle(false);
                        window.scrollTo({ top: 0, behavior: "smooth" });
                      }}
                      className="w-full text-left px-4 py-2 flex items-center justify-between hover:bg-slate-400/30"
                    >
                      Lens Portfolio
                      <IoChevronForwardOutline
                        color="white"
                        size={22}
                        className="pl-1"
                      />
                    </button>
                    {subLensPortfolioToggle && (
                      <ul className="absolute top-0 left-full w-3/4 xs:w-full mt-0 ml-[0.015rem] bg-primary shadow-lg rounded-sm py-1 text-white z-50">
                        <Accordion
                          expanded={expanded === "panel1"}
                          onChange={handlePortfolioChange("panel1")}
                          sx={{
                            backgroundColor: "transparent",
                            boxShadow: "none",
                          }}
                        >
                          <AccordionSummary
                            expandIcon={<IoChevronDown color="white" />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                          >
                            <div className="text-white">Progressive</div>
                          </AccordionSummary>
                          <div className="bg-lightPrimary">
                            <Link
                              to={NavStrings.ZSERIESDS}
                              onClick={() => {
                                setDropdownToggle(false);
                                setSubLensPortfolioToggle(false);
                                closeMenu();
                                window.scrollTo({ top: 0, behavior: "smooth" });
                              }}
                              className="text-tinybase px-4 py-2 w-full text-white flex hover:bg-slate-400/30"
                            >
                              Clariviz Z Series DS
                            </Link>
                            <Link
                              to={NavStrings.ZSERIES20}
                              onClick={() => {
                                setDropdownToggle(false);
                                setSubLensPortfolioToggle(false);
                                closeMenu();
                                window.scrollTo({ top: 0, behavior: "smooth" });
                              }}
                              className="text-tinybase px-4 py-2 w-full text-white flex hover:bg-slate-400/30"
                            >
                              Clariviz Z Series 2.0
                            </Link>
                            <Link
                              to={NavStrings.ZSERIES10}
                              onClick={() => {
                                setDropdownToggle(false);
                                setSubLensPortfolioToggle(false);
                                closeMenu();
                                window.scrollTo({ top: 0, behavior: "smooth" });
                              }}
                              className="text-tinybase px-4 py-2 w-full text-white flex hover:bg-slate-400/30"
                            >
                              Clariviz Z Series 1.0
                            </Link>
                            <Link
                              to={NavStrings.DIGIPRO20}
                              onClick={() => {
                                setDropdownToggle(false);
                                setSubLensPortfolioToggle(false);
                                closeMenu();
                                window.scrollTo({ top: 0, behavior: "smooth" });
                              }}
                              className="text-tinybase px-4 py-2 w-full text-white flex hover:bg-slate-400/30"
                            >
                              Clariviz Digi Pro 2.0
                            </Link>
                            <Link
                              to={NavStrings.DIGIPRO10}
                              onClick={() => {
                                setDropdownToggle(false);
                                setSubLensPortfolioToggle(false);
                                closeMenu();
                                window.scrollTo({ top: 0, behavior: "smooth" });
                              }}
                              className="text-tinybase px-4 py-2 w-full text-white flex hover:bg-slate-400/30"
                            >
                              Clariviz Digi Pro 1.0
                            </Link>
                            <Link
                              to={NavStrings.PROVIEW20}
                              onClick={() => {
                                setDropdownToggle(false);
                                setSubLensPortfolioToggle(false);
                                closeMenu();
                                window.scrollTo({ top: 0, behavior: "smooth" });
                              }}
                              className="text-tinybase px-4 py-2 w-full text-white flex hover:bg-slate-400/30"
                            >
                              Clariviz ProView 2.0
                            </Link>
                            <Link
                              to={NavStrings.PROVIEW10}
                              onClick={() => {
                                setDropdownToggle(false);
                                setSubLensPortfolioToggle(false);
                                closeMenu();
                                window.scrollTo({ top: 0, behavior: "smooth" });
                              }}
                              className="text-tinybase px-4 py-2 w-full text-white flex hover:bg-slate-400/30"
                            >
                              Clariviz ProView 1.0
                            </Link>
                            <Link
                              to={NavStrings.BENDIGITALPAL}
                              onClick={() => {
                                setDropdownToggle(false);
                                setSubLensPortfolioToggle(false);
                                closeMenu();
                                window.scrollTo({ top: 0, behavior: "smooth" });
                              }}
                              className="text-tinybase px-4 py-2 w-full text-white flex hover:bg-slate-400/30"
                            >
                              Ben Digital PALs
                            </Link>
                          </div>
                        </Accordion>
                        <Accordion
                          expanded={expanded === "panel2"}
                          onChange={handlePortfolioChange("panel2")}
                          sx={{
                            backgroundColor: "transparent",
                            boxShadow: "none",
                          }}
                        >
                          <AccordionSummary
                            expandIcon={<IoChevronDown color="white" />}
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <div className="text-white">Bifocal</div>
                          </AccordionSummary>
                          <div className="bg-lightPrimary">
                            <Link
                              to={NavStrings.RXBIFOCAL}
                              onClick={() => {
                                setDropdownToggle(false);
                                setSubLensPortfolioToggle(false);
                                closeMenu();
                                window.scrollTo({ top: 0, behavior: "smooth" });
                              }}
                              className="text-tinybase px-4 py-2 w-full text-white flex hover:bg-slate-400/30"
                            >
                              Clariviz RX Bifocal
                            </Link>
                          </div>
                        </Accordion>
                        <Accordion
                          expanded={expanded === "panel3"}
                          onChange={handlePortfolioChange("panel3")}
                          sx={{
                            backgroundColor: "transparent",
                            boxShadow: "none",
                          }}
                        >
                          <AccordionSummary
                            expandIcon={<IoChevronDown color="white" />}
                            aria-controls="panel3-content"
                            id="panel3-header"
                          >
                            <div className="text-white">Single Vision</div>
                          </AccordionSummary>
                          <div className="bg-lightPrimary">
                            <Link
                              to={NavStrings.CLARIVIZSINGLEVISION}
                              onClick={() => {
                                setDropdownToggle(false);
                                setSubLensPortfolioToggle(false);
                                closeMenu();
                                window.scrollTo({ top: 0, behavior: "smooth" });
                              }}
                              className="text-tinybase px-4 py-2 w-full text-white flex hover:bg-slate-400/30"
                            >
                              Clariviz SV
                            </Link>
                          </div>
                        </Accordion>
                        <Accordion
                          expanded={expanded === "panel4"}
                          onChange={handlePortfolioChange("panel4")}
                          sx={{
                            backgroundColor: "transparent",
                            boxShadow: "none",
                          }}
                        >
                          <AccordionSummary
                            expandIcon={<IoChevronDown color="white" />}
                            aria-controls="panel4-content"
                            id="panel4-header"
                          >
                            <div className="text-white">Special Lens</div>
                          </AccordionSummary>
                          <div className="bg-lightPrimary/70">
                            <Link
                              to={NavStrings.CLEARDRIVEPAL}
                              onClick={() => {
                                setDropdownToggle(false);
                                setSubLensPortfolioToggle(false);
                              }}
                              className="text-tinybase px-4 py-2 w-full text-white flex hover:bg-slate-400/30"
                            >
                              Clariviz ClearDrive PAL
                            </Link>
                            <Link
                              to={NavStrings.CLEARDRIVESV}
                              onClick={() => {
                                setDropdownToggle(false);
                                setSubLensPortfolioToggle(false);
                              }}
                              className="text-tinybase px-4 py-2 w-full text-white flex hover:bg-slate-400/30"
                            >
                              Clariviz ClearDrive SV
                            </Link>
                            <Link
                              to={NavStrings.CLARIVIZOFFICE}
                              onClick={() => {
                                setDropdownToggle(false);
                                setSubLensPortfolioToggle(false);
                              }}
                              className="text-tinybase px-4 py-2 w-full text-white flex hover:bg-slate-400/30"
                            >
                              Clariviz Office
                            </Link>
                            <Link
                              to={NavStrings.PIXELDIGI}
                              onClick={() => {
                                setDropdownToggle(false);
                                setSubLensPortfolioToggle(false);
                              }}
                              className="text-tinybase px-4 py-2 w-full text-white flex hover:bg-slate-400/30"
                            >
                              Pixel Digi
                            </Link>
                            <Link
                              to={NavStrings.QUICHROMES}
                              onClick={() => {
                                setDropdownToggle(false);
                                setSubLensPortfolioToggle(false);
                              }}
                              className="text-tinybase px-4 py-2 w-full text-white flex hover:bg-slate-400/30"
                            >
                              Clariviz Quichromes
                            </Link>
                          </div>
                        </Accordion>
                      </ul>
                    )}
                  </li>
                  <li className="px-4 py-2 hover:bg-slate-400/30">
                    <Link
                      to={NavStrings.LENSTECHNOLOGIES}
                      onClick={() => {
                        setDropdownToggle(false);
                        setSubLensPortfolioToggle(false);
                        closeMenu();
                        window.scrollTo({ top: 0, behavior: "smooth" });
                      }}
                    >
                      Lens Technologies
                    </Link>
                  </li>
                  <li className="text-tinybase relative">
                    <button
                      onClick={() => {
                        setSubLensCoatingToggle(!subLensCoatingToggle);
                        setSubLensPortfolioToggle(false);
                        window.scrollTo({ top: 0, behavior: "smooth" });
                      }}
                      className="w-full text-left px-4 py-2 flex items-center
                     justify-between hover:bg-slate-400/30"
                    >
                      Lens Coatings & Protection
                      <IoChevronForwardOutline
                        color="white"
                        size={22}
                        className="pl-1"
                      />
                      {subLensCoatingToggle && (
                        <ul className="absolute top-0 left-full w-full mt-0 ml-[0.015rem] bg-primary shadow-lg rounded-sm py-1 text-white">
                          <li className="text-tinybase px-4 py-2 hover:bg-slate-400/30">
                            <Link
                              to={NavStrings.ULTIPROUVCOATING}
                              onClick={() => {
                                setDropdownToggle(false);
                                setSubLensCoatingToggle(false);
                                closeMenu();
                                window.scrollTo({ top: 0, behavior: "smooth" });
                              }}
                            >
                              ULTIPRO UV Coating
                            </Link>
                          </li>
                          <li className="text-tinybase px-4 py-2 hover:bg-slate-400/30">
                            <Link
                              to={NavStrings.ULTIPROBLUECOATING}
                              onClick={() => {
                                setDropdownToggle(false);
                                setSubLensCoatingToggle(false);
                                closeMenu();
                                window.scrollTo({ top: 0, behavior: "smooth" });
                              }}
                            >
                              ULTIPRO Bluecut Coating
                            </Link>
                          </li>
                          <li className="text-tinybase px-4 py-2 hover:bg-slate-400/30">
                            <Link
                              to={NavStrings.HERCUECOATING}
                              onClick={() => {
                                setDropdownToggle(false);
                                setSubLensCoatingToggle(false);
                                closeMenu();
                                window.scrollTo({ top: 0, behavior: "smooth" });
                              }}
                            >
                              Hercue Coating
                            </Link>
                          </li>
                        </ul>
                      )}
                    </button>
                  </li>
                  <li className="px-4 py-2 hover:bg-slate-400/30">
                    <Link
                      to={NavStrings.LENSCOLOUR}
                      onClick={() => {
                        setDropdownToggle(false);
                        setSubLensPortfolioToggle(false);
                        closeMenu();
                        window.scrollTo({ top: 0, behavior: "smooth" });
                      }}
                    >
                      Lens Tints
                    </Link>
                  </li>
                  <li className="px-4 py-2 hover:bg-slate-400/30">
                    <Link
                      to={NavStrings.LENSPERSONALIZATION}
                      onClick={() => {
                        setDropdownToggle(false);
                        setSubLensPortfolioToggle(false);
                        closeMenu();
                        window.scrollTo({ top: 0, behavior: "smooth" });
                      }}
                    >
                      Lens Personalization
                    </Link>
                  </li>
                </ul>
              )}
            </li>
            <li className="text-tinybase relative" ref={dropdownAboutRef}>
              <button
                onClick={() => {
                  setAboutusToggle(!aboutusToggle);
                  setDropdownToggle(false);
                }}
                className="w-max-content text-left rounded-sm flex items-center px-2 py-2 hover:bg-slate-400/30 "
              >
                About Us
                <IoChevronDown size={22} className="pl-1" />
              </button>
              {aboutusToggle && (
                <ul className="w-4/5 ml-2 mt-1 bg-primary shadow-lg rounded-sm py-1 text-white">
                  <li className="px-4 py-2 hover:bg-slate-400/30">
                    <Link
                      to={NavStrings.CLARIVIZBYBENFRANKLIN}
                      onClick={() => {
                        setDropdownToggle(false);
                        setSubLensPortfolioToggle(false);
                        setAboutusToggle(false);
                        closeMenu();
                        window.scrollTo({ top: 0, behavior: "smooth" });
                      }}
                    >
                      Clariviz by Ben Franklin
                    </Link>
                  </li>
                  {/* <li className="px-4 py-2 hover:bg-slate-400/30">
                    <Link
                      to={NavStrings.LENSMANUFACTURINGUNIT}
                      onClick={() => {
                        setDropdownToggle(false);
                        setSubLensPortfolioToggle(false);
                        setAboutusToggle(false);
                        closeMenu();
                        window.scrollTo({ top: 0, behavior: "smooth" });
                      }}
                    >
                      Lens Manufacturing Unit
                    </Link>
                  </li> */}
                </ul>
              )}
            </li>
            <li className="flex">
              <Link
                to={NavStrings.CONTACTUS}
                className="text-left px-2 py-2 rounded-sm hover:bg-slate-400/30"
                onClick={closeMenu}
              >
                Contact Us
              </Link>
            </li>
            <li className="flex">
              <Link
                to={NavStrings.STORELOCATOR}
                className="text-left px-2 py-2 rounded-sm hover:bg-slate-400/30"
                onClick={closeMenu}
              >
                Store Locator
              </Link>
            </li>
          </ul>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
