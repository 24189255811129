import React from "react";
import headerImg from "../../assets/images/ben-digital-pal/headerImg.webp";
import BenDigital20 from "../../assets/images/ben-digital-pal/BEN DIGITAL 2.0.webp";
import BenDigital10 from "../../assets/images/ben-digital-pal/BEN DIGITAL 1.0.webp";
import StoreImg from "../../assets/images/store-locator.jpg";
import { ImageTextLayout, SEO } from "../../components";
import { NavStrings, UrlConstants } from "../../constants";

const BenDigitalPAL = () => {
  const UrlLink = UrlConstants.base_url.concat(NavStrings.BENDIGITALPAL);
  return (
    <section className="w-[100%] max-w-screen-2xl mx-auto">
      <SEO
        title="BEN Digital PALs - Advanced Progressive Lenses"
        description="Explore the BEN Digital PALs, including BEN DIGITAL 1.0 and 2.0, featuring advanced digital surface technology, enhanced field of vision, and superior clarity. Discover the benefits and MFH options for each lens."
        keywords="BEN Digital PALs, BEN DIGITAL 1.0, BEN DIGITAL 2.0, advanced progressive lenses, digital surface technology, wide field of vision, high clarity lenses, minimum fitting height"
        name="BEN Digital PALs"
        type="website"
        canonicallink={UrlLink}
      />
      <div className="relative w-full h-[35vh] md:h-[50vh] lg:h-[55vh] xl:h-[60vh] z-2">
        <img
          src={headerImg}
          alt="Main header pic"
          className="w-[100%] h-[100%] object-cover"
        />
      </div>
      <div className="py-8 flex flex-col space-y-4 px-custom-padding">
        <h1 className="text-2xl md:text-3xl text-primary font-bold">
          BEN Digital PALs
        </h1>
      </div>
      {/* img & BENefits */}
      <div className="px-custom-padding pb-8 grid grid-cols-1 gap-12 md:grid-cols-2">
        <div className="px-6 flex flex-col gap-8">
          {/* img 2.0 */}
          <div className="flex flex-col gap-4">
            <h3 className="text-2xl font-bold text-primary">BEN DIGITAL 2.0</h3>
            <div>
              <img
                src={BenDigital20}
                alt="BenDigital 2.0"
                className="w-[100%] h-[100%]"
              />
            </div>
          </div>
          {/* benefits 2.0 */}
          <div className="flex flex-col gap-2">
            <p className="text-2xl font-bold text-primary">
              BEN DIGITAL 2.0 Benefits
            </p>

            <ul className="w-[90%] flex flex-col gap-4">
              <li className="list-disc ml-2 text-base text-black  w-full md:w-[98%]">
                Advanced digital surface technology with extreme precision and
                wider field of vision.
              </li>
              <li className="list-disc ml-2 text-base text-black  w-full md:w-[98%]">
                Natural color perception.
              </li>
              <li className="list-disc ml-2 text-base text-black  w-full md:w-[98%]">
                End to end clarity, Fast focus and good dynamic vision all day
                long.
              </li>
              <li className="list-disc ml-2 text-base text-black  w-full md:w-[98%]">
                Thin, flat and light weight lenses.
              </li>
              <li className="list-disc ml-2 text-base text-black  w-full md:w-[98%]">
                Natural posture in any activities.
              </li>
            </ul>
          </div>
          {/* MFH 2.0 */}
          <div className="flex flex-col gap-2">
            <p className="text-2xl font-bold text-primary">
              MFH (Minimum Fitting Height)
            </p>
            <ul className="w-[90%] flex flex-col gap-4">
              <li className="list-disc ml-2 text-base text-black  w-full md:w-[98%]">
                16 mm | 18 mm | 20 mm
              </li>
            </ul>
          </div>
        </div>

        <div className="px-6 flex flex-col gap-8">
          {/* img 1.0 */}
          <div className="flex flex-col gap-4">
            <h3 className="text-2xl font-bold text-primary">BEN DIGITAL 1.0</h3>
            <div>
              <img
                src={BenDigital10}
                alt="BenDigital 1.0"
                className="w-[100%] h-[100%]"
              />
            </div>
          </div>
          {/* benefits 1.0 */}
          <div className="flex flex-col gap-2">
            <p className="text-2xl font-bold text-primary">
              BEN DIGITAL 1.0 Benefits
            </p>
            <ul className="w-[90%] flex flex-col gap-4">
              <li className="list-disc ml-2 text-base text-black  w-full md:w-[98%]">
                Digital surface technology.
              </li>
              <li className="list-disc ml-2 text-base text-black  w-full md:w-[98%]">
                Up to 20% wide field of vision compared to regular Pal’s.
              </li>
              <li className="list-disc ml-2 text-base text-black  w-full md:w-[98%]">
                Better peripheral vision and less distortion.
              </li>
              <li className="list-disc ml-2 text-base text-black  w-full md:w-[98%]">
                Natural image perception.
              </li>
              <li className="list-disc ml-2 text-base text-black  w-full md:w-[98%]">
                Thin and light weight lenses.
              </li>
            </ul>
          </div>
          {/* MFH 1.0 */}
          <div className="flex flex-col gap-2">
            <p className="text-2xl font-bold text-primary">
              MFH (Minimum Fitting Height)
            </p>
            <ul className="w-[90%] flex flex-col gap-4">
              <li className="list-disc ml-2 text-base text-black  w-full md:w-[98%]">
                16 mm | 18 mm | 20 mm
              </li>
            </ul>
          </div>
        </div>
      </div>
      <ImageTextLayout
        imageSrc={StoreImg}
        subtitle="Clariviz Store Locator"
        title="Find a Store near You"
        description="With over 500 hospital retail locations and 8000+ mom-and-pop stores nationwide, 
        you're never far from the exceptional products and services you love. Whether you're planning 
        a visit or need to check availability, we're here to make your shopping experience seamless and 
        convenient. Discover your nearest store today!"
        buttonTxt="Show Me Now"
      />
    </section>
  );
};

export default BenDigitalPAL;
