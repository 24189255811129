import React from "react";
import HeaderImg from "../../assets/images/lenscolour-header.png";
import TintData from "./TintsData";
import { ImageTextLayout, SEO } from "../../components";
import StoreImg from "../../assets/images/store-locator.jpg";
import ZseriesDS from "../../assets/images/zseriesds.jpg";
import { NavStrings, UrlConstants } from "../../constants";

const LensColours = () => {
  const UrlLink = UrlConstants.base_url.concat(NavStrings.LENSCOLOUR);
  return (
    <section className="w-[100%] max-w-screen-2xl mx-auto">
      <SEO
        title="Lens Tints - Clariviz"
        description="Explore the range of lens tints offered by Clariviz. Learn how different tints can enhance your vision and provide eye protection. Find out the benefits of each tint color and its impact on your eyewear."
        keywords="Lens Tints, Clariviz, eyewear tints, lens colors, vision enhancement, eye protection"
        name="Lens Tints"
        type="website"
        canonicallink={UrlLink}
      />
      <div className="relative w-full h-[30vh] xs:h-[35vh] small:h-[40vh] md:h-[50vh] lg:h-[55vh] xl:h-[60vh]">
        <img
          src={HeaderImg}
          alt="Lens Color header pic"
          className="w-[100%] h-[100%] object-cover"
        />
      </div>
      <div className="pt-8 flex flex-col space-y-6 px-custom-padding">
        <h1 className="text-3xl text-primary font-bold">Lens Tints</h1>
        <p className="text-base text-black leading-8 w-[98%]">
          Whether it serves as eye protection or an enhancement to your eyewear
          accessory, the color of your lenses is also very important. Here are
          the tints we offer, along with how they are beneficial to your vision.
        </p>
        <div className="py-8 flex flex-col gap-8">
          {TintData.map((item, index) => (
            <div key={index} className="grid grid-cols-1 gap-5">
              <div className="flex flex-col gap-2 md:flex-row md:gap-12 items-start md:items-center">
                <h4 className="text-black font-bold w-[120px]">{item.color}</h4>
                <div className="grid grid-cols-7 gap-6">
                  {item.tints.map((tints, index) => (
                    <div
                      key={index}
                      className={`
                        ${tints.bgColor} ${tints.textColor}
                        w-[50px] h-[50px]
                        xs:w-[60px] xs:h-[60px]
                        small:w-[80px] small:h-[80px]
                        md:w-[90px] md:h-[90px]
                        flex items-center justify-center
                        rounded-full
                        text-tinybase
                        small:text-base
                      `}
                    >
                      {tints.percentage}
                    </div>
                  ))}
                </div>
              </div>
              <div>{item.description}</div>
            </div>
          ))}
        </div>
      </div>
      <ImageTextLayout
        imageSrc={ZseriesDS}
        subtitle="Clariviz Z Series DS"
        title="The most technologically advanced progressive lens, tailored for you."
        description="Clariviz Z Series DS lenses adapt to the demands of modern life. The technology meets the challenging visual needs of the most active presbyopes, who require clear and stable vision, even when they’re engaged in highly dynamic activities.."
        buttonTxt="Z Series DS"
        buttonTxt2="Z Series 2.0"
        buttonTxt3="Z Series 1.0"
        reverse
      />
      <ImageTextLayout
        imageSrc={StoreImg}
        subtitle="Clariviz Store Locator"
        title="Find a Store near You"
        description="With over 500 hospital retail locations and 8000+ mom-and-pop stores nationwide, 
        you're never far from the exceptional products and services you love. Whether you're planning 
        a visit or need to check availability, we're here to make your shopping experience seamless and 
        convenient. Discover your nearest store today!"
        buttonTxt="Show Me Now"
      />
    </section>
  );
};

export default LensColours;
