import React from "react";
import headerImg from "../../assets/images/quichromes/headerImg.webp";
import QuichromeImg from "../../assets/images/quichromes/quichrome.webp";
import { SEO } from "../../components";
import { NavStrings, UrlConstants } from "../../constants";
const Quichromes = () => {
  const UrlLink = UrlConstants.base_url.concat(NavStrings.QUICHROMES);
  return (
    <section className="w-[100%] max-w-screen-2xl mx-auto">
      <SEO
        title="Clariviz Quichromes: High-Performance Photochromic Lenses"
        description="Experience unparalleled visual comfort and protection with our high-performance photochromic lenses, designed to adapt seamlessly to changing light conditions. Whether you're indoors or outdoors, these lenses ensure optimal clarity and comfort."
        keywords="photochromic lenses, high-performance lenses, visual comfort, UV protection, adaptive light technology, dynamic tint adjustment, rapid transition, superior UV protection, blue light filter, consistent color perception, reduced glare, eye strain, scratch-resistant coating, anti-reflection coating, versatile lens designs"
        name="Quichromes"
        type="website"
        canonicallink={UrlLink}
      />
      <div className="relative w-full h-[30vh] xs:h-[35vh] small:h-[40vh] md:h-[50vh] lg:h-[55vh] xl:h-[60vh]">
        <img
          src={headerImg}
          alt="Quichromes pic"
          className="w-[100%] h-[100%] object-cover"
        />
      </div>
      <div className="py-8 flex flex-col space-y-4 px-custom-padding">
        <h1 className="text-2xl md:text-3xl text-primary font-bold">
          Clariviz Quichromes: High-Performance Photochromic Lenses
        </h1>
        <p className="text-base text-black leading-8 w-full md:w-[98%]">
          Experience unparalleled visual comfort and protection with our
          high-performance photochromic lenses, designed to adapt seamlessly to
          changing light conditions. Whether you're indoors or outdoors, these
          lenses ensure optimal clarity and comfort.
        </p>
        <div className="md:py-4 w-full mx-auto">
          <img src={QuichromeImg} alt="Clariviz Quichrome" />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 py-8">
          {/* Ideal Wearer */}
          <div className="flex flex-col gap-4">
            <p className="font-bold text-xl text-black">Ideal Wearer</p>
            <ul className="w-full md:w-[90%] flex flex-col gap-4 ml-4">
              <li className="list-disc text-base text-black  w-full md:w-[98%]">
                <strong>Outdoor Enthusiasts:</strong> Perfect for those who love
                hiking, cycling, and other outdoor activities, providing clear
                vision and UV protection.
              </li>
              <li className="list-disc text-base text-black  w-full md:w-[98%]">
                <strong>Athletes:</strong> Enhance performance with lenses that
                adapt to changing light conditions, ensuring clarity and comfort
                during sports.
              </li>
              <li className="list-disc text-base text-black  w-full md:w-[98%]">
                <strong>Drivers:</strong> Improve driving safety and comfort
                with lenses that adjust to varying light conditions on the road.
              </li>
              <li className="list-disc text-base text-black  w-full md:w-[98%]">
                <strong>Everyday Use:</strong> Ideal for anyone who transitions
                frequently between indoor and outdoor environments.
              </li>
            </ul>
          </div>
          {/* Key Features */}
          <div className="flex flex-col gap-4">
            <div className="font-bold text-xl text-black">Key Features:</div>
            <ul className="w-full md:w-[90%] flex flex-col gap-6">
              <div className="flex flex-col gap-2">
                <li className="list-disc ml-4 text-primary/90 text-base font-bold">
                  Adaptive Light Technology:
                </li>
                <div className="flex flex-col gap-4">
                  <li className="ml-8 text-base text-black w-full md:w-[98%]">
                    <strong>Dynamic Tint Adjustment:</strong> Our photochromic
                    lenses automatically darken in bright sunlight and return to
                    a clear state in low light or indoors, providing you with
                    perfect vision in any environment.
                  </li>
                  <li className="ml-8 text-base text-black w-full md:w-[98%]">
                    <strong>Rapid Transition:</strong> Enjoy one of the fastest
                    transition times in the market, ensuring your lenses adapt
                    as quickly as you do.
                  </li>
                </div>
              </div>
              <div className="flex flex-col gap-2">
                <li className="list-disc ml-4 text-primary/90 text-base font-bold">
                  Superior UV Protection:& Blue Light Filter:
                </li>
                <div className="flex flex-col gap-4">
                  <li className="ml-8 text-base text-black w-full md:w-[98%]">
                    <strong>100% Protection:</strong> Shield your eyes from
                    harmful UVA and UVB rays & Blue light, reducing the risk of
                    eye damage and long-term health issues.
                  </li>
                </div>
              </div>
              <div className="flex flex-col gap-4">
                <li className="list-disc ml-4 text-primary/90 text-base font-bold">
                  Enhanced Visual Experience:
                </li>
                <div className="flex flex-col gap-4">
                  <li className="ml-8 text-base text-black w-full md:w-[98%]">
                    <strong>Consistent Color Perception:</strong> Our lenses
                    maintain natural color balance, so you see colors
                    accurately, regardless of the level of tint.
                  </li>
                  <li className="ml-8 text-base text-black w-full md:w-[98%]">
                    <strong>Reduced Glare and Eye Strain:</strong> Minimize
                    glare from bright lights, ensuring comfortable vision and
                    reduced eye fatigue throughout the day.
                  </li>
                </div>
              </div>
              <div className="flex flex-col gap-4">
                <li className="list-disc ml-4 text-primary/90 text-base font-bold">
                  Durability and Performance:
                </li>
                <div className="flex flex-col gap-4">
                  <li className="ml-8 text-base text-black w-full md:w-[98%]">
                    <strong>Scratch-Resistant Coating:</strong> Enhanced
                    durability to withstand daily wear and tear, keeping your
                    lenses in pristine condition.
                  </li>
                  <li className="ml-8 text-base text-black w-full md:w-[98%]">
                    <strong>Premium Anti-Reflection Coating:</strong> Repels
                    water, dust, and smudges, making them easy to clean and
                    maintain.
                  </li>
                </div>
              </div>
              <div className="flex flex-col gap-4">
                <li className="list-disc ml-4 text-primary/90 text-base font-bold">
                  Versatile Design Options:
                </li>
                <div className="flex flex-col gap-4">
                  <li className="ml-8 text-base text-black w-full md:w-[98%]">
                    <strong>Compatibility with Various Lens Types:</strong>{" "}
                    Available in single vision and progressive designs to suit
                    all visual needs.
                  </li>
                  <li className="ml-8 text-base text-black w-full md:w-[98%]">
                    <strong>Wide Range of Styles:</strong> Choose from a variety
                    of lens shapes and frame styles to match your personal
                    aesthetic.
                  </li>
                </div>
              </div>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Quichromes;
