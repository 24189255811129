import HeaderImg from "../../assets/images/potenscoating/headerImg.png";
import { ImageTextLayout, SEO } from "../../components";
import PotensCoating1 from "../../assets/images/potenscoating/potenscoating1.jpg";
import PotensCoating2 from "../../assets/images/potenscoating/potenscoating2.jpg";
import React from "react";
import StoreImg from "../../assets/images/store-locator.jpg";
import { NavStrings, UrlConstants } from "../../constants";

const HercueCoating = () => {
  const UrlLink = UrlConstants.base_url.concat(NavStrings.HERCUECOATING);
  return (
    <section className="w-[100%] max-w-screen-2xl mx-auto">
      <SEO
        title="Clariviz Hercue Coating"
        description="Discover Clariviz Hercue coating, a premium scratch-resistant 
        coating designed to protect lenses from scratches and abrasions. Learn about its 
        advanced formulation, durability, and benefits for long-lasting lens protection."
        keywords="Hercue coating, Clariviz, scratch-resistant coating, lens protection, 
        durable coatings, lens technology"
        name="Clariviz Hercue Coating"
        type="website"
        canonicallink={UrlLink}
      />
      <div className="relative w-full h-[30vh] xs:h-[35vh] small:h-[40vh] md:h-[50vh] lg:h-[55vh] xl:h-[60vh]">
        <img
          src={HeaderImg}
          alt="Potents coating header pic"
          className="w-[100%] h-[100%] object-cover"
        />
      </div>
      <div className="py-8 flex flex-col space-y-4 md:space-y-6 px-custom-padding">
        <div>
          <h1 className="text-2xl md:text-3xl text-primary font-bold">
            Clariviz Hercue Coating
          </h1>
          <h4 className="font-medium text-lg md:text-xl text-primary py-2">
            Enhancing Vision. Enhancing Life.
          </h4>
        </div>
        <div className="grid grid-cols-1 gap-12 md:gap-20">
          <div className="grid grid-cols-1 gap-12 md:grid-cols-2 md:gap-4">
            <div className="flex flex-col gap-6">
              <p className="text-15pt text-black leading-8 w-[98%]">
                Ben Franklin presents Clariviz Hercue coating, a premium
                scratch-resistant coating that represents the pinnacle of
                protective coatings, designed to safeguard surfaces from
                scratches and abrasions. It utilizes state-of-the-art materials
                and manufacturing processes to achieve superior durability and
                performance, compared to standard scratch-resistant coatings.
              </p>
              <p className="text-15pt text-black leading-8 w-[98%]">
                The primary function of this scratch-resistant coating is to
                provide an extremely tough and resilient barrier that shields
                the surface from scratches caused by everyday use and
                environmental factors. It acts as a sacrificial layer, absorbing
                the impact and friction that would otherwise damage the
                underlying material.
              </p>
            </div>
            <div className="w-full md:w-2/4 mx-auto">
              <img src={PotensCoating1} alt="Potens Coating 1" />
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="flex flex-col gap-12">
              <div className="flex flex-col gap-4">
                <h1 className="text-3xl text-primary font-bold">
                  What it does
                </h1>
                <div className="flex flex-col gap-4">
                  <p className="text-base text-black leading-8 w-[98%]">
                    This premium scratch-resistant coating employs an advanced
                    formulation containing specialized polymers, nanoparticles,
                    or ceramic materials renowned for their exceptional hardness
                    and scratch resistance.
                  </p>
                  <p className="text-base text-black leading-8 w-[98%]">
                    These materials are engineered to create a robust protective
                    barrier that significantly reduces the susceptibility of the
                    surface to scratches and abrasions. The coating adheres
                    tightly to the surface, forming a durable and resilient
                    shield that can withstand various types of mechanical
                    stresses.
                  </p>
                </div>
              </div>
              <div className="w-full md:hidden">
                <img src={PotensCoating2} alt="Potens Coating 2" />
              </div>
              <div className="flex flex-col gap-4">
                <h1 className="text-3xl text-primary font-bold">Benefits</h1>
                <div className="flex flex-col gap-4">
                  <p className="text-base text-black leading-8 w-[98%]">
                    This coating provides scratch protection, ensuring that
                    surfaces remain scratch-free and pristine even after
                    prolonged use. This durability translates to extended
                    lifespan of the lenses.
                  </p>
                </div>
              </div>
            </div>
            <div className="hidden md:block md:w-2/4 md:mx-auto">
              <img src={PotensCoating2} alt="Potens Coating 2" />
            </div>
          </div>
        </div>
      </div>
      {/* Store Locator */}
      <ImageTextLayout
        imageSrc={StoreImg}
        subtitle="Clariviz Store Locator"
        title="Find a Store near You"
        description="With over 500 hospital retail locations and 8000+ mom-and-pop stores nationwide, 
    you're never far from the exceptional products and services you love. Whether you're planning 
    a visit or need to check availability, we're here to make your shopping experience seamless and 
    convenient. Discover your nearest store today!"
        buttonTxt="Show Me Now"
      />
    </section>
  );
};

export default HercueCoating;
