import React from "react";
import CareerHeaderImg from "../assets/images/careers-header.jpg";
import { SEO } from "../components";
import { NavStrings, UrlConstants } from "../constants";

const Careers = () => {
  const UrlLink = UrlConstants.base_url.concat(NavStrings.CAREERS);
  return (
    <section className="w-[100%] max-w-screen-2xl mx-auto">
      <SEO
        title="Careers at Ben Franklin - Join Our Dynamic Team"
        description="Explore career opportunities at Ben Franklin. Learn about our values, culture of learning and rewards, and the benefits of joining our dynamic and diverse team."
        keywords="Ben Franklin careers, optical retail jobs, career opportunities, company values, work culture, employee rewards, learning and development"
        name="Careers at Ben Franklin"
        type="website"
        canonicallink={UrlLink}
      />
      <div className="relative w-full h-[30vh] xs:h-[35vh] small:h-[40vh] md:h-[50vh] lg:h-[55vh] xl:h-[60vh]">
        <img
          src={CareerHeaderImg}
          alt="Careers header pic"
          className="w-[100%] h-[100%] object-cover"
        />
      </div>
      <div className="py-4 flex flex-col px-custom-padding">
        <h1 className="text-3xl text-primary font-bold">
          Explore Life at Ben Franklin
        </h1>
        <div className="flex flex-col gap-4 py-4">
          <p className="tracking-wide w-[90%]">
            Ben Franklin is a dynamic company that understands quality eyewear
            as per the needs of customers everywhere. We are always looking to
            hire talented professionals who speak the language of our customers.
          </p>
          <p className="tracking-wide w-[90%]">
            We pride ourselves on our committed and diverse work culture that
            believes in nurturing client relationships that go beyond optical
            sales. Moreover, our multi-branded retail outlets boast of a
            pan-India presence, making it a convenient shopping experience for
            our customers.
          </p>
          <p className="tracking-wide w-[90%]">
            By joining the company’s diversified workforce, come and make a
            difference to the future of our business—and your career!
          </p>
        </div>
        <div className="flex flex-col py-4">
          <h2 className="text-lg text-primary font-medium">
            Our Values: Why we are unique
          </h2>
          <div className="flex flex-col gap-4 mt-2">
            <p className="tracking-wide w-[90%]">
              We are proud of our caring, dynamic and responsive team who put
              customers first. Our team members work together to embrace the
              company’s ethical practices that are aligned with our business
              goals. Our mission is to empower employees to know they are part
              of something unique in the retail optical industry. That’s why at
              Ben Franklin, our team values workplace morale, growth and loyalty
              to drive the business forward.
            </p>
            <p className="tracking-wide w-[90%]">
              We strive to create a safe and supportive workplace and encourage
              visionary thinking and leadership development at all levels. The
              most rewarding thing is to see our employees derive job enrichment
              and grow their career with us. By fostering an inclusive and
              collaborative atmosphere, we are paving the way for a positive
              work culture.
            </p>
          </div>
        </div>
        <div className="flex flex-col py-4">
          <h2 className="text-lg text-primary font-medium">
            A culture of learning and rewards
          </h2>
          <div className="flex flex-col gap-4 mt-2">
            <p className="tracking-wide w-[90%]">
              We seek to hire smart and talented professionals who pursue their
              goals passionately and responsibly. Our leadership team supports
              each employee’s professional and personal aspirations and provides
              adequate resources, on-job training, coaching and mentoring to
              help them stand apart from the crowd.
            </p>
            <p className="tracking-wide w-[90%]">
              Ben Franklin offers employees ample opportunities to contribute
              their best life at work by aligning their career goals with the
              company’s strategic mission. We champion our employees and help
              them flourish in a competitive environment by enhancing their
              skill sets and product knowledge. It is why we seek team members
              who are driven and passionate about self-improvement.
            </p>
            <p className="tracking-wide w-[90%]">
              Our company thrives on a culture that encourages job rotation and
              cross-functionality upskilling. We offer continuous learning in
              terms of career progression by providing real-time feedback,
              succession planning and performance improvement plans that empower
              individuals to reach their optimum as professionals in a
              modern-day workplace.
            </p>
            <p className="tracking-wide w-[90%]">
              Most importantly, the company prides itself in recognizing and
              rewarding employees for great work done.
            </p>
          </div>
        </div>
        <div className="py-8">
          <button className="bg-primary text-white px-6 py-2 rounded-md font-medium transition-all hover:bg-primary/90">
            Email Your CV
          </button>
        </div>
      </div>
    </section>
  );
};

export default Careers;
