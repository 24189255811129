import React from "react";
import HeaderImg from "../../assets/images/LensPersonalization/lenspersonalization-header.jpg";
import LensPersonalizationImg from "../../assets/images/LensPersonalization/header.png";
import ZseriesDS from "../../assets/images/zseriesds.jpg";
import StoreImg from "../../assets/images/store-locator.jpg";
import { ImageTextLayout, SEO } from "../../components";
import PersonalizationData from "./PersonalizationData";
import { NavStrings, UrlConstants } from "../../constants";

const LensPersonalization = () => {
  const UrlLink = UrlConstants.base_url.concat(NavStrings.LENSPERSONALIZATION);
  const twoColumnMap = PersonalizationData.reduce((acc, item, index) => {
    if (index % 2 === 0) {
      acc.push([item]);
    } else {
      acc[acc.length - 1].push(item);
    }
    return acc;
  }, [] as Array<typeof PersonalizationData>).map(([left, right]) => ({
    left,
    right,
  }));
  return (
    <section className="w-[100%] max-w-screen-2xl mx-auto">
      <SEO
        title="Lens Personalization - Clariviz"
        description="Discover how Clariviz enhances your eyewear experience 
        with advanced lens personalization technologies. Perfectly match your frame, lenses, and coatings."
        keywords="Lens Personalization, Clariviz, advanced eyewear, personalized lenses, 
        lens coatings, custom lenses"
        name="Lens Personalization"
        type="website"
        canonicallink={UrlLink}
      />
      <div className="relative w-full h-[30vh] xs:h-[35vh] small:h-[40vh] md:h-[50vh] lg:h-[55vh] xl:h-[60vh]">
        <img
          src={HeaderImg}
          alt="lens personalization pic"
          className="w-[100%] h-[100%] object-cover"
        />
      </div>
      <div className="pt-8 md:py-8 flex flex-col space-y-4 md:space-y-6 px-custom-padding">
        <h1 className="text-2xl md:text-3xl text-primary font-bold">
          Lens Personalization
        </h1>
        <p className="text-base text-black leading-8 w-[98%]">
          Get personalization like never before, with technologies that
          perfectly match your frame, lenses and lens coatings.
        </p>
        <div>
          <div className="py-4 w-full md:w-3/5 mx-auto">
            <img
              src={LensPersonalizationImg}
              alt="lens personalization pic"
              className="w-[100%] h-[100%] object-cover bg-slate-100 border-2"
            />
          </div>

          {/* Lens Personalization angles */}
          {/* laptop */}
          <div className="hidden md:flex md:flex-col md:pt-12">
            {twoColumnMap.map((row, rowIndex) => (
              <div
                key={rowIndex}
                className={`grid grid-cols-2 ${
                  rowIndex % 2 !== 0 ? "bg-slate-400/10" : ""
                }`}
              >
                {[row.left, row.right].map(
                  (item, colIndex) =>
                    item && (
                      <div
                        key={colIndex}
                        className={`grid grid-cols-4 ${
                          rowIndex === 0
                            ? colIndex === 0
                              ? "border"
                              : "border-r border-t border-b"
                            : colIndex === 0
                            ? "border-b border-l border-r"
                            : "border-r border-b"
                        } p-6`}
                      >
                        <div className="col-span-1 w-20 h-20 mlg:w-35 mlg:h-35 lg:w-40 lg:h-40 flex items-center justify-center">
                          <img
                            src={item.imgSrc}
                            alt={item.altText}
                            className="w-[100%] h-[100%] object-cover"
                          />
                        </div>
                        <div className="col-span-3 px-8">
                          <h2 className="font-bold text-primary">
                            {item.title}
                          </h2>
                          <p className="text-base text-black leading-8 w-[98%]">
                            {item.description}
                          </p>
                        </div>
                      </div>
                    )
                )}
              </div>
            ))}
          </div>

          {/* mobile */}
          <div className="my-12 md:hidden">
            {PersonalizationData.map((row, index) => (
              <div
                key={index}
                className={`grid grid-cols-2 gap-4 ${
                  index % 2 === 0 ? "border-r border-l" : "border"
                } p-6 ${index === 0 && "border-t"} ${
                  index % 2 !== 0 && "bg-slate-400/10"
                }`}
              >
                <div className="col-span-1 w-40 h-40  flex items-center justify-center">
                  <img
                    src={row.imgSrc}
                    alt={row.altText}
                    className="w-[100%] h-[100%] object-cover"
                  />
                </div>
                <div className="col-span-3">
                  <h2 className="font-bold text-primary text-lg">
                    {row.title}
                  </h2>
                  <p className="text-base text-black leading-8 w-[98%]">
                    {row.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <ImageTextLayout
        imageSrc={ZseriesDS}
        subtitle="Clariviz Z Series DS"
        title="The most technologically advanced progressive lens, tailored for you."
        description="Clariviz Z Series DS lenses adapt to the demands of modern life. The technology meets the challenging visual needs of the most active presbyopes, who require clear and stable vision, even when they’re engaged in highly dynamic activities.."
        buttonTxt="Z Series DS"
        buttonTxt2="Z Series 2.0"
        buttonTxt3="Z Series 1.0"
        reverse
      />
      <ImageTextLayout
        imageSrc={StoreImg}
        subtitle="Clariviz Store Locator"
        title="Find a Store near You"
        description="With over 500 hospital retail locations and 8000+ mom-and-pop stores nationwide, 
        you're never far from the exceptional products and services you love. Whether you're planning 
        a visit or need to check availability, we're here to make your shopping experience seamless and 
        convenient. Discover your nearest store today!"
        buttonTxt="Show Me Now"
      />
    </section>
  );
};

export default LensPersonalization;
