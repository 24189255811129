import BackVertexDistance from "../../assets/images/LensPersonalization/back-vertex-distance.png";
import FrameDimensions from "../../assets/images/LensPersonalization/frame-dimensions.png";
import NasopupilarDistance from "../../assets/images/LensPersonalization/nasopupilar-distance.png";
import NearWorkingDistance from "../../assets/images/LensPersonalization/near-working-window.png";
import PantoscopicAngle from "../../assets/images/LensPersonalization/pantoscopic-angle.png";
import PrescriptionAddition from "../../assets/images/LensPersonalization/prescription-addition.png";
import PupilarHeights from "../../assets/images/LensPersonalization/pupilar-heights.png";
import WrapAngle from "../../assets/images/LensPersonalization/wrap-angle.png";

const PersonalizationData = [
  {
    imgSrc: PrescriptionAddition,
    altText: "Prescription & Addition",
    title: "Prescription & Addition",
    description:
      "Calculates the power that the wearer will truly perceive once the lenses are fitted on the frame.",
  },
  {
    imgSrc: PantoscopicAngle,
    altText: "Pantoscopic Angle",
    title: "Pantoscopic Angle",
    description:
      "This is the angle in the vertical plane between the optical axis of a spectacle lens and the visual axis of the eye in the primary position.",
  },
  {
    imgSrc: NasopupilarDistance,
    altText: "Nasopupillary Distance",
    title: "Nasopupillary Distance",
    description:
      "Is defined as the distance from the axis of symmetry of the face to the center of the pupil.",
  },
  {
    imgSrc: NearWorkingDistance,
    altText: "Near Working Distance",
    title: "Near Working Distance",
    description:
      "This is the distance from the lens to the typical reading position for the wearer.",
  },
  {
    imgSrc: PupilarHeights,
    altText: "Pupillary Heights",
    title: "Pupillary Heights",
    description:
      "Is the vertical distance between the pupil center and the deepest part of the lens shape.",
  },
  {
    imgSrc: FrameDimensions,
    altText: "Frame Dimensions",
    title: "Frame Dimensions",
    description:
      "Frame dimensions are used to calculate the final diameter, thickness of the lens and improve the efficiency of the optimization.",
  },
  {
    imgSrc: WrapAngle,
    altText: "Wrap Angle",
    title: "Wrap Angle",
    description: "Frame curvature",
  },
  {
    imgSrc: BackVertexDistance,
    altText: "Back Vertex Distance",
    title: "Back Vertex Distance",
    description:
      "Distance between the cornea and the back surface of the lens.",
  },
];

export default PersonalizationData;
