import BecomePartner from "../assets/images/BecomePartner.jpeg";
import Careers from "../assets/images/Careers.jpg";
import ContactusPageBanner from "../../src/assets/images/contactus.webp";
import StoreImg from "../../src/assets/images/store-locator.jpg";
import { ImageTextLayout, SEO } from "../components";
import React from "react";
import { NavStrings, UrlConstants } from "../constants";

const ContactUs: React.FC = () => {
  const UrlLink = UrlConstants.base_url.concat(NavStrings.CONTACTUS);
  const sendMailTo = (receiver: string) => {
    const mailToText = `mailto:${receiver}`;
    window.location.href = mailToText;
  };

  const openDialPad = (number: string) => {
    const callTo = `tel:${number}`;
    window.location.href = callTo;
  };

  return (
    <section className="w-[100%] max-w-screen-2xl mx-auto">
      <SEO
        title="Contact Us - Clariviz"
        description="Get in touch with Clariviz for customer service, business inquiries, and career opportunities. Find our contact information, office address, and more."
        keywords="Clariviz contact, customer service, business inquiries, careers, office address"
        name="Clariviz"
        type="website"
        canonicallink={UrlLink}
      />
      <div className="relative w-full h-[30vh] xs:h-[35vh] small:h-[40vh] md:h-[50vh] lg:h-[55vh] xl:h-[60vh]">
        <img
          src={ContactusPageBanner}
          alt="Contact us banner"
          className="w-[100%] h-[100%] object-cover"
        />
      </div>
      <div className="content-section py-8 px-custom-padding flex flex-col md:flex-row justify-between h-full">
        <div className="flex flex-col w-full md:w-1/2">
          <div className="mb-8">
            <div className="text-2xl font-medium mb-2 text-primary">
              Corporate Office:
            </div>
            <div className="mb-4">
              <a
                href="https://goo.gl/maps/5NiuNHqx9g7Abw918"
                target="_blank"
                rel="noreferrer"
                className="flex-row space-y-[0.15rem] transition-all"
              >
                <div>Eyegear Optics India Pvt. Ltd.,</div>
                <div>Road #2, House No # 8-2-277/A/9,</div>
                <div>Soudagar's Silver Breeze, Banjara Hills,</div>
                <div>Hyderabad – 500034, Telangana, India.</div>
              </a>
            </div>
            <div className="text-2xl font-medium mb-2 text-primary">
              Customer Service:
            </div>
            <div className="mb-4">
              <div onClick={() => sendMailTo("feedback@benfranklin.in")}>
                <span>Email : </span>
                <span className="text-blue-500 cursor-pointer">
                  feedback@benfranklin.in
                </span>
              </div>
              <div onClick={() => openDialPad("040-40381111")}>
                <span>Phone No : </span>
                <span className="text-blue-500 cursor-pointer">
                  040 - 40381111
                </span>
              </div>
              <div onClick={() => openDialPad("+91-8886807010")}>
                <span>Mobile No : </span>
                <span className="text-blue-500 cursor-pointer">
                  +91 - 8886807010
                </span>
              </div>
              <div>Timing : 10.00 AM to 6.00 PM (Mon - Sat)</div>
            </div>
            <div className="text-2xl font-medium mb-2 text-primary">
              Business Enquiries:
            </div>
            <div className="mb-4">
              <div onClick={() => sendMailTo("corporate@benfranklin.in")}>
                <span>Email : </span>
                <span className="text-blue-500 cursor-pointer">
                  corporate@benfranklin.in
                </span>
              </div>
              <div onClick={() => openDialPad("+91-7799777830")}>
                <span>Contact No : </span>
                <span className="text-blue-500 cursor-pointer">
                  +91 - 7799777830
                </span>
              </div>
            </div>
            <div className="text-2xl font-medium mb-2 text-primary">
              Careers
            </div>
            <div onClick={() => sendMailTo("careers@benfranklin.in")}>
              <span>Email : </span>
              <span className="text-blue-500 cursor-pointer">
                careers@benfranklin.in
              </span>
            </div>
          </div>
        </div>
        <div className="border-2 content-page-section w-full md:w-1/2">
          <iframe
            title="benfranklin map"
            className="w-full h-64 md:h-full"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d951.6807305332906!2d78.4337138!3d17.4250798!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb972b29a30e59%3A0xd1fe1d3805cd28e1!2sBenfranklin%20Opticians!5e0!3m2!1sen!2sin!4v1601036118760!5m2!1sen!2sin"
          ></iframe>
        </div>
      </div>
      <ImageTextLayout
        imageSrc={BecomePartner}
        title="Become a Clariviz Partner"
        description="As a leading manufacturer of spectacle lenses, we provide unparalleled quality, innovation,
            and support to our business partners. By collaborating with us, you gain access to our extensive range of
            premium lenses, cutting-edge technology, and expert insights, enabling you to meet the diverse needs of your customers."
        description2="Experience the advantages of a partnership with us and grow your business with confidence. Become a business partner today and join us in achieving excellence."
        buttonTxt="Become a Clariviz Partner"
      />
      <ImageTextLayout
        imageSrc={Careers}
        title="Careers at Clariviz"
        description="We're a dynamic and innovative eyewear company which values its
            employees and is committed to creating a positive, inclusive work environment.
            If you're passionate about eyewear and looking for an exciting and
            upwardly-mobile career opportunity, then you're welcome to apply!"
        buttonTxt="Send your CV"
        reverse
      />
      <ImageTextLayout
        imageSrc={StoreImg}
        subtitle="Clariviz Store Locator"
        title="Find a Store near You"
        description="With over 500 hospital retail locations and 8000+ mom-and-pop stores nationwide,
            you're never far from the exceptional products and services you love. Whether you're planning
            a visit or need to check availability, we're here to make your shopping experience seamless and
            convenient. Discover your nearest store today!"
        buttonTxt="Show Me Now"
      />
    </section>
  );
};

export default ContactUs;
