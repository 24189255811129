import SpatialVision from "../../../assets/images/lenstechnologies/Spatial Vision.png";
import EyeFocusProfiling from "../../../assets/images/lenstechnologies/Eye Focus Profiling.png";
import VisionStability from "../../../assets/images/lenstechnologies/Vision Stability.png";
import FitConfiguration from "../../../assets/images/lenstechnologies/Fit Configuration.png";
import RayTracing from "../../../assets/images/lenstechnologies/Ray Tracing.png";
import BinocularVision from "../../../assets/images/lenstechnologies/Binocular Vision.png";
import DualSideTechnology from "../../../assets/images/lenstechnologies/Dual Side Technology.png";

const ZSeriesDSData = [
  {
    imgSrc: SpatialVision,
    altTxt: "Spatial Vision",
    title: "Spatial Vision",
    description: "Naturally good vision, even when you're on the move.",
  },
  {
    imgSrc: EyeFocusProfiling,
    altTxt: "Eye Focus Profiling",
    title: "Eye Focus Profiling",
    description: "Flawless vision from any angle.",
  },
  {
    imgSrc: VisionStability,
    altTxt: "Vision Stability",
    title: "Vision Stability",
    description: "Reduces the swim effect when moving.",
  },
  {
    imgSrc: FitConfiguration,
    altTxt: "Individual Fit Configuration",
    title: "Individual Fit Configuration",
    description: "Optimizes the vision zones based on the unique wearer.",
  },
  {
    imgSrc: FitConfiguration,
    altTxt: "Frame Fit Configuration",
    title: "Frame Fit Configuration",
    description: "Optimizes lens performance in any frame.",
  },
  {
    imgSrc: FitConfiguration,
    altTxt: "Lens Fit Configuration",
    title: "Lens Fit Configuration",
    description: "Optimizes the aesthetic appearance of the lens.",
  },
  {
    imgSrc: RayTracing,
    altTxt: "Ray Tracing",
    title: "Ray Tracing",
    description:
      "Precise vision at every distance and in every direction of gaze",
  },
  {
    imgSrc: BinocularVision,
    altTxt: "Binocular Vision",
    title: "Binocular Vision",
    description:
      "The lens's special geometry enables wider fields of view and sharper vision.",
  },
  {
    imgSrc: DualSideTechnology,
    altTxt: "Dual Side Technology",
    title: "Dual Side Technology",
    description:
      "Lenses with the highest level of visual comfort and clarity, particularly for close- up activities.",
  },
];
const ZSeries20Data = [
  {
    imgSrc: SpatialVision,
    altTxt: "Spatial Vision",
    title: "Spatial Vision",
    description: "Naturally good vision, even when you’re on the move.",
  },
  {
    imgSrc: EyeFocusProfiling,
    altTxt: "Eye Focus Profiling",
    title: "Eye Focus Profiling",
    description: "Flawless vision from any angle.",
  },
  {
    imgSrc: VisionStability,
    altTxt: "Vision Stability",
    title: "Vision Stability",
    description: "Reduces the swim effect when moving.",
  },
  {
    imgSrc: FitConfiguration,
    altTxt: "Individual Fit Configuration",
    title: "Individual Fit Configuration",
    description: "Optimizes the vision zones based on the unique wearer.",
  },
  {
    imgSrc: FitConfiguration,
    altTxt: "Frame Fit Configuration",
    title: "Frame Fit Configuration",
    description: "Optimizes lens performance in any frame.",
  },
  {
    imgSrc: FitConfiguration,
    altTxt: "Lens Fit Configuration",
    title: "Lens Fit Configuration",
    description: "Optimizes the aesthetic appearance of the lens.",
  },
  {
    imgSrc: RayTracing,
    altTxt: "Ray Tracing",
    title: "Ray Tracing",
    description:
      "Precise vision at every distance and in every direction of gaze",
  },
];
const ZSeries10Data = [
  {
    imgSrc: SpatialVision,
    altTxt: "Spatial Vision",
    title: "Spatial Vision",
    description: "Naturally good vision, even when you’re on the move.",
  },
  {
    imgSrc: EyeFocusProfiling,
    altTxt: "Eye Focus Profiling",
    title: "Eye Focus Profiling",
    description: "Flawless vision from any angle.",
  },
  {
    imgSrc: VisionStability,
    altTxt: "Vision Stability",
    title: "Vision Stability",
    description: "Reduces the swim effect when moving.",
  },
  {
    imgSrc: FitConfiguration,
    altTxt: "Individual Fit Configuration",
    title: "Individual Fit Configuration",
    description: "Optimizes the vision zones based on the unique wearer.",
  },
  {
    imgSrc: FitConfiguration,
    altTxt: "Frame Fit Configuration",
    title: "Frame Fit Configuration",
    description: "Optimizes lens performance in any frame.",
  },
  {
    imgSrc: FitConfiguration,
    altTxt: "Lens Fit Configuration",
    title: "Lens Fit Configuration",
    description: "Optimizes the aesthetic appearance of the lens.",
  },
  {
    imgSrc: RayTracing,
    altTxt: "Ray Tracing",
    title: "Ray Tracing",
    description:
      "Precise vision at every distance and in every direction of gaze",
  },
];
const Digipro20Data = [
  {
    imgSrc: VisionStability,
    altTxt: "Vision Stability",
    title: "Vision Stability",
    description: "Reduces the swim effect when moving.",
  },
  {
    imgSrc: RayTracing,
    altTxt: "Ray Tracing",
    title: "Ray Tracing",
    description:
      "Precise vision at every distance and in every direction of gaze",
  },
  {
    imgSrc: FitConfiguration,
    altTxt: "Individual Fit Configuration",
    title: "Individual Fit Configuration",
    description: "Optimizes the vision zones based on the unique wearer.",
  },
  {
    imgSrc: FitConfiguration,
    altTxt: "Frame Fit Configuration",
    title: "Frame Fit Configuration",
    description: "Optimizes lens performance in any frame.",
  },
  {
    imgSrc: FitConfiguration,
    altTxt: "Lens Fit Configuration",
    title: "Lens Fit Configuration",
    description: "Optimizes the aesthetic appearance of the lens.",
  },
];
const Digipro10Data = [
  {
    imgSrc: VisionStability,
    altTxt: "Vision Stability",
    title: "Vision Stability",
    description: "Reduces the swim effect when moving.",
  },
  {
    imgSrc: RayTracing,
    altTxt: "Ray Tracing",
    title: "Ray Tracing",
    description:
      "Precise vision at every distance and in every direction of gaze",
  },
  {
    imgSrc: FitConfiguration,
    altTxt: "Individual Fit Configuration",
    title: "Individual Fit Configuration",
    description: "Optimizes the vision zones based on the unique wearer.",
  },
  {
    imgSrc: FitConfiguration,
    altTxt: "Frame Fit Configuration",
    title: "Frame Fit Configuration",
    description: "Optimizes lens performance in any frame.",
  },
  {
    imgSrc: FitConfiguration,
    altTxt: "Lens Fit Configuration",
    title: "Lens Fit Configuration",
    description: "Optimizes the aesthetic appearance of the lens.",
  },
];
const ProViwe20 = [
  {
    imgSrc: VisionStability,
    altTxt: "Vision Stability",
    title: "Vision Stability",
    description: "Reduces the swim effect when moving.",
  },
];

export {
  ZSeriesDSData,
  ZSeries20Data,
  ZSeries10Data,
  Digipro20Data,
  Digipro10Data,
  ProViwe20,
};
