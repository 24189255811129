import Digipro10 from "../../assets/images/digipro10/CLARIVIZ DIGIPRO 1.0.webp";
import { Digipro10Data } from "./Data/ClarivizSeriesData";
import { ImageTextLayout, SEO } from "../../components";
import React from "react";
import StoreImg from "../../assets/images/store-locator.jpg";
import headerImg from "../../assets/images/digipro10/headerImg.webp";
import { NavStrings, UrlConstants } from "../../constants";

interface DigiproItem {
  imgSrc: string;
  altTxt: string;
  title: string;
  description: string;
}

interface ColumnMap {
  first: DigiproItem;
  second?: DigiproItem;
  third?: DigiproItem;
}

const ClarivizDigipro10 = () => {
  const UrlLink = UrlConstants.base_url.concat(NavStrings.DIGIPRO10);
  const twoColumnMap: ColumnMap[] = Digipro10Data.reduce((acc, item, index) => {
    if (index % 3 === 0) {
      acc.push({
        first: index === 0 ? item : Digipro10Data[index - 1] || undefined,
        second: index === 0 ? Digipro10Data[index + 1] || undefined : item,
        third: index === 0 ? undefined : Digipro10Data[index + 1] || undefined,
      });
    }
    return acc;
  }, [] as ColumnMap[]);
  return (
    <section className="w-[100%] max-w-screen-2xl mx-auto">
      <SEO
        title="Clariviz Digipro 1.0 - Quality Lenses with Balanced Vision Zones"
        description="Discover Clariviz Digipro 1.0 lenses, perfect for demanding users who need balanced vision zones with comfort and performance. Ideal for progressive users seeking quality lenses."
        keywords="Clariviz Digipro 1.0 lenses, balanced vision zones, quality lenses, progressive lenses, comfortable design, large visual fields"
        name="Clariviz Digipro 1.0 Lenses"
        type="website"
        canonicallink={UrlLink}
      />
      <div className="relative w-full h-[30vh] xs:h-[35vh] small:h-[40vh] md:h-[50vh] lg:h-[55vh] xl:h-[60vh]">
        <img
          src={headerImg}
          alt="Digipro 1.0 pic"
          className="w-[100%] h-[100%] object-cover"
        />
      </div>
      <div className="py-8 flex flex-col space-y-4 px-custom-padding">
        <h1 className="text-2xl md:text-3xl text-primary font-bold">
          Clariviz Digipro 1.0
        </h1>
        <p className="text-base text-black leading-8 w-full md:w-[98%]">
          Clariviz Digipro 1.0 is a good option for discerning users who need a
          quality lens with a balance between all the vision zones aligned with
          visual performance and comfort.
        </p>

        <div className="py-4 w-full md:w-3/5 mx-auto">
          <img src={Digipro10} alt="Clariviz DigiPro 1.0" />
        </div>
        {/* 3 Column */}
        <div className="pb-12 w-full grid grid-cols-1 gap-8 md:gap-4 md:grid-cols-3 md:justify-between md:p-8">
          <div className="flex flex-col gap-2">
            <p className="font-bold text-xl text-black">Ideal Wearer</p>
            <ul className="w-full md:w-[90%] flex flex-col gap-4">
              <li className="list-disc text-base text-black w-full md:w-[98%]">
                Perfect design for demanding progressive users looking for
                balance in the three vision areas.
              </li>
            </ul>
          </div>
          <div className="flex flex-col gap-2">
            <p className="font-bold text-xl text-black">Benefits</p>
            <ul className="w-full md:w-[90%] flex flex-col gap-4">
              <li className="list-disc text-base text-black w-full md:w-[98%]">
                Harmonious performance in the near, intermediate and distance
                zones.
              </li>
              <li className="list-disc text-base text-black w-full md:w-[98%]">
                Comfortable design.
              </li>
              <li className="list-disc text-base text-black w-full md:w-[98%]">
                Large visual fields.
              </li>
            </ul>
          </div>
          <div className="flex flex-col gap-6">
            <div className="flex flex-col gap-2">
              <p className="font-bold text-xl text-black">
                MFH (Minimum Fitting Height)
              </p>
              <ul className="w-full md:w-[90%] flex flex-col gap-4">
                <li className="list-disc text-base text-black w-full md:w-[98%]">
                  14 mm | 16 mm | 18 mm
                </li>
              </ul>
            </div>
            <div className="flex flex-col gap-2">
              <p className="font-bold text-xl text-black">Personalization</p>
              <ul>
                <li className="list-disc text-base text-black w-full md:w-[98%]">
                  Fully Personalized Progressive Lens
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* Technologies */}
        <div>
          <h1 className="text-3xl text-primary font-bold">Technologies</h1>
          <div className="hidden md:pt-8 md:flex md:flex-col">
            {twoColumnMap.map((row, index) => (
              <div
                key={index}
                className={`grid grid-cols-3 ${
                  index % 2 !== 0 ? "bg-slate-400/10" : ""
                }`}
              >
                {[row.first, row.second, row.third].map(
                  (item, colIndex) =>
                    item && (
                      <div
                        key={colIndex}
                        className={`${
                          index === 0
                            ? colIndex === 0
                              ? "border"
                              : "border-r border-t border-b"
                            : colIndex === 0
                            ? "border-b border-l border-r"
                            : "border-r border-b border-t"
                        } p-[0.85rem]`}
                      >
                        <div className="flex items-center gap-x-4">
                          <div className="w-[24%] h-[24%]">
                            <img
                              src={item.imgSrc}
                              alt={item.altTxt}
                              className="w-full h-full"
                            />
                          </div>
                          <div className="font-bold text-primary text-base">
                            {item.title}
                          </div>
                        </div>
                        <p className="pt-4 tracking-wide">{item.description}</p>
                      </div>
                    )
                )}
              </div>
            ))}
          </div>
          <div className="py-8 md:hidden">
            {Digipro10Data.map((item, index) => (
              <div
                key={index}
                className={`grid grid-cols-1 ${
                  index % 2 !== 0 && "bg-slate-400/10"
                }
                `}
              >
                <div
                  className={`${
                    index % 2 === 0 ? "border" : "border-l border-r"
                  } p-[0.85rem]`}
                >
                  <div className="flex items-center gap-x-4">
                    <div className="w-[24%] h-[24%]">
                      <img
                        src={item.imgSrc}
                        alt={item.altTxt}
                        className="w-full h-full"
                      />
                    </div>
                    <div className="font-bold text-primary text-base">
                      {item.title}
                    </div>
                  </div>
                  <p className="pt-4 tracking-wide">{item.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* Store Locator */}
      <ImageTextLayout
        imageSrc={StoreImg}
        subtitle="Clariviz Store Locator"
        title="Find a Store near You"
        description="With over 500 hospital retail locations and 8000+ mom-and-pop stores nationwide, 
      you're never far from the exceptional products and services you love. Whether you're planning 
      a visit or need to check availability, we're here to make your shopping experience seamless and 
      convenient. Discover your nearest store today!"
        buttonTxt="Show Me Now"
      />
    </section>
  );
};

export default ClarivizDigipro10;
