import React from "react";
import LensTechnologiesHeaderImg from "../../assets/images/technologies-header.webp";
import ZseriesDS from "../../assets/images/zseriesds.jpg";
import StoreImg from "../../assets/images/store-locator.jpg";

import { ImageTextLayout, SEO } from "../../components";
import LensTechnologyData from "./LensTechnologyData";
import { NavStrings, UrlConstants } from "../../constants";
const LensTechnologies = () => {
  const UrlLink = UrlConstants.base_url.concat(NavStrings.LENSTECHNOLOGIES);
  return (
    <section className="w-[100%] max-w-screen-2xl mx-auto">
      <SEO
        title="Clariviz Lens Technologies"
        description="Discover Clariviz's world-class lens technologies designed to provide you with uncompromising 
        vision accuracy in all conditions. Explore our cutting-edge technologies and how they enhance your lenses."
        keywords="Lens Technologies, Clariviz, advanced eyewear, 
        cutting-edge lens technologies, vision accuracy, eyewear innovations"
        name="Clariviz Lens Technologies"
        type="website"
        canonicallink={UrlLink}
      />
      <div className="relative w-full h-[30vh] xs:h-[35vh] small:h-[40vh] md:h-[50vh] lg:h-[55vh] xl:h-[60vh]">
        <img
          src={LensTechnologiesHeaderImg}
          alt="Lens Technologies header pic with girl smiling with specs on from back to front"
          className="w-[100%] h-[100%] object-cover"
        />
      </div>
      <div className="py-8 flex flex-col space-y-6 px-custom-padding">
        <h1 className="text-2xl md:text-3xl text-primary font-bold">
          Clariviz Lens Technologies
        </h1>
        <p className="text-base text-black leading-8 w-[98%]">
          Clariviz offers, in all of our lenses, ideal combinations of the
          following nine world-class, cutting- edge technologies. Thus ensuring
          that your lenses provide you with uncompromisingly accurate vision, in
          all conditions.
        </p>

        {/* Technologies */}

        <div className="pt-6 md:mx-12">
          {LensTechnologyData.map((item, index) => (
            <div
              key={index}
              className={`grid grid-cols-1 md:grid-cols-8
              ${index % 2 === 0 ? "border" : "border-l border-r"} ${
                index === 7 && "border-b"
              } ${index % 2 !== 0 ? "bg-slate-400/10" : ""}
              `}
            >
              <div className="md:col-span-2 p-[0.85rem] border-r grid grid-cols-2 md:grid-cols-3 items-center justify-center gap-2">
                <div className="w-full h-full flex items-center justify-center">
                  <img
                    src={item.imgSrc}
                    alt={item.title}
                    className="md:col-span-1"
                  />
                </div>
                <div className="text-primary font-bold md:col-span-2">
                  {item.title}
                </div>
              </div>
              <div className="md:col-span-6 p-[0.85rem]">
                <p>{item.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* Clariviz Z Series DS */}
      <ImageTextLayout
        imageSrc={ZseriesDS}
        subtitle="Clariviz Z Series DS"
        title="The most technologically advanced progressive lens, tailored for you."
        description="Clariviz Z Series DS lenses adapt to the demands of modern life. The technology meets the challenging visual needs of the most active presbyopes, who require clear and stable vision, even when they’re engaged in highly dynamic activities.."
        buttonTxt="Find Out More"
        reverse
      />
      {/* Store Locator */}
      <ImageTextLayout
        imageSrc={StoreImg}
        subtitle="Clariviz Store Locator"
        title="Find a Store near You"
        description="With over 500 hospital retail locations and 8000+ mom-and-pop stores nationwide, 
        you're never far from the exceptional products and services you love. Whether you're planning 
        a visit or need to check availability, we're here to make your shopping experience seamless and 
        convenient. Discover your nearest store today!"
        buttonTxt="Show Me Now"
      />
    </section>
  );
};

export default LensTechnologies;
