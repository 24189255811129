import React, { useEffect, useState } from "react";

import StoreLocatorImage from "../../src/assets/images/storeLocator_Mobile.jpg";
import StoreLocatorPageBanner from "../../src/assets/images/storeLocator.jpg";
import cities from "../utils/cities.json";
import locationIcon from "../../src/assets/images/newLocation.png";
import phoneIcon from "../../src/assets/images/newPhone.png";
import states from "../utils/states.json";
import stores from "../utils/stores.json";
import { SEO } from "../components";
import { NavStrings, UrlConstants } from "../constants";

interface StoreDetails {
  name: string;
  address: string;
  mobile: string;
  email: string;
  phone: string[];
  map: string;
}

interface City {
  value: string;
  label: string;
}

interface storesData {
  city: string;
  stores: StoreDetails[];
}

interface CitiesData {
  stateID: number;
  cities: City[];
}

const StoreLocator = () => {
  const UrlLink = UrlConstants.base_url.concat(NavStrings.STORELOCATOR);
  const [storeData, setStoreData] = useState<storesData | null>(null);
  const [city, setCity] = useState<string>("Hyderabad");
  const [state, setState] = useState<string>("0");
  const [citiesData, setCitiesData] = useState<CitiesData | null>(null);
  const [map, setMap] = useState<string>(
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2691.7638397305273!2d78.43095068151713!3d17.424791746114273!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xd1fe1d3805cd28e1!2sBenfranklin%20Opticians!5e0!3m2!1sen!2sin!4v1651919091549!5m2!1sen!2sin"
  );

  useEffect(() => {
    if (parseInt(state) === 0) {
      return;
    } else {
      const res: any =
        Array.isArray(cities) &&
        cities.filter((city) => {
          return city.stateID === parseInt(state);
        });
      setCitiesData(res[0]);
    }
  }, [state]);

  useEffect(() => {
    if (city.toLocaleLowerCase() === "hyderabad") {
      const res: any =
        Array.isArray(stores) &&
        stores.filter((val) => {
          return val.city === city.toLocaleLowerCase();
        });
      setStoreData(res[0]);
    } else {
      const res: any =
        Array.isArray(stores) &&
        stores.filter((val) => {
          return val.city === city;
        });
      setStoreData(res[0]);
    }
  }, [city]);

  return (
    <div className="mx-auto">
      <SEO
        title="Store Locator - Clariviz"
        description="Find the nearest Clariviz store from over 500 locations across India. Explore our wide range of eyewear products and services at a store near you."
        keywords="Clariviz store locator, find store, eyewear store, optical store, Clariviz locations"
        name="Clariviz"
        type="website"
        canonicallink={UrlLink}
      />
      <div className="image-section">
        <picture>
          <source media="(min-width: 768px)" srcSet={StoreLocatorPageBanner} />
          <img
            src={StoreLocatorImage}
            className="w-full h-auto md:w-auto"
            alt="Contact"
          />
        </picture>
      </div>
      <div className="content-section py-8 px-custom-padding flex flex-col">
        <h1 className="text-3xl font-bold mb-4 text-primary">
          Find Our Stores
        </h1>
        <p className="text-base leading-8 w-[98%] text-slate-800">
          We have more than 500+ stores across 250 cities and 26 states in
          India. Walk into the nearest one and explore your options from the
          world's leading brands. Discover which shape and colours bring out the
          best in you. We have got hundreds of frames to suit all shapes, tastes
          and pockets.
        </p>
        <div className="my-12 flex flex-col items-center justify-center md:flex-row md:justify-center md:items-center md:space-x-4 mb-6">
          <select
            className="mb-4 md:mb-0 p-2 border rounded-md w-full md:w-1/4 bg-transparent"
            value={state}
            onChange={(e) => {
              setState(e.target.value);
              console.log(e.target.value);
            }}
          >
            <option value="0">Select your state</option>
            {Array.isArray(states) &&
              states.map((state) => (
                <option key={state.value} value={state.value}>
                  {state.label}
                </option>
              ))}
          </select>

          <select
            className="p-2 border rounded-md w-full md:w-1/4 bg-transparent"
            value={city}
            onChange={(e) => {
              setCity(e.target.value);
              console.log(e.target.value);
            }}
          >
            <option value="">Select your city</option>
            {citiesData !== null &&
              Array.isArray(citiesData.cities) &&
              citiesData?.cities.map((city: any) => (
                <option key={city.value} value={city.value}>
                  {city.label}
                </option>
              ))}
          </select>
        </div>
        <div className="ben-map-details">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="city-name p-4 mb-4 border border-primary rounded-md">
              <h2 className="font-medium text-primary capitalize"> {city} </h2>
            </div>
            <div className="city-name p-4 mb-4 border border-primary rounded-md">
              <h2 className="font-medium text-primary">
                {" "}
                Total Stores:{" "}
                {storeData?.stores.length ? storeData?.stores.length : 0}{" "}
              </h2>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div style={{ height: "500px", overflowY: "auto" }}>
              <div className=" location-route-inner">
                {storeData !== null &&
                  storeData !== undefined &&
                  Array.isArray(storeData.stores) &&
                  storeData.stores.map((store, index) => (
                    <div
                      key={index}
                      className="flex flex-col md:flex-row items-center md:items-start bg-white rounded-md shadow-sm p-4 mb-4 border border-slate-300"
                    >
                      <div className=" mr-4 md:mr-8 mb-4 md:mb-0 hover:cursor-pointer">
                        <img
                          src={locationIcon}
                          alt="Location Icon"
                          className="w-8 h-8 md:w-12 md:h-12"
                          onClick={() => setMap(store.map)}
                        />
                      </div>
                      <div className="flex-1">
                        <h3 className="text-lg font-bold mb-2">{store.name}</h3>
                        <p className="text-gray-600 mb-2">{store.address}</p>
                        <p className="text-gray-600 mb-2">
                          Mobile: {store.mobile}
                        </p>
                        <a
                          href={`mailto:${store.email}`}
                          className="text-blue-500 hover:underline"
                        >
                          {store.email}
                        </a>
                      </div>
                      <div className="mr-4 md:mr-8 mb-4 md:mb-0">
                        {store.phone.map((phone, phoneIndex) => (
                          <a
                            key={phoneIndex}
                            href={`tel:${phone}`}
                            className="mr-2 hover:cursor-pointer"
                          >
                            <img
                              src={phoneIcon}
                              alt="Phone Icon"
                              className="w-6 h-6 md:w-8 md:h-8 hover:cursor-pointer"
                            />
                          </a>
                        ))}
                      </div>
                    </div>
                  ))}
              </div>
            </div>

            <div>
              <iframe
                title="benfranklin map"
                src={map}
                className="w-full h-full"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StoreLocator;
